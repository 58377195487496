<div clas="container-fluid">
    <div class="alert-success" *ngIf="false">
        <img style="margin-right: 1em;" src="../assets/images/checkCircle.svg" alt="successMsg">
        <span class="alert-close" >&times;</span>
        <span>{{successMessage}}</span>

    </div>
    <div class="alert-failure" *ngIf="false">
        <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
        <span class="alert-close">&times;</span>
        <span>{{failureMessage}}</span>
    </div>
    <div class="mt-3 m-4" >
        <mat-tab-group animationDuration="0ms" (selectedIndex)="selectedTabIndex"
            (selectedTabChange)="onTabChanged($event)">

            <form [formGroup]="adminForm">
                <mat-tab label="ISE" *ngIf="isAuthenticUser">
                    <div class="row subHeading m-3" style="font-style: italic;">
                        This tab is to mark a supplier as active/inactive
                    </div>
                    <div class="row pb-5 ">
                        <div class="col-md-6 ms-3 pl-3">
                            <div class="boxHeader">
                                <div class="d-flex justify-content-between p-1">
                                    <span class=" cursorptr ms-2 commonFont ">ISE </span>
                                    <span class="cursorptr me-2  commonFont" style="color:#2688DA" (keyup)="toggle()" (click)="toggle()">Toggle ISE </span>
                                </div>
                                <div class="cursorptr ms-2 d-flex" >
                                    <input type="text" class="form-control addsearch search-tmna "
                                    placeholder="Search here" (keyup)="filteredISE()" [(ngModel)]="inputSearchISE" [ngModelOptions]="{standalone: true}">
                                    <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                                    style="position: absolute;right: 10px;top: 36px;">

                                </div>

                            </div>

                            <div class="boxISE me-1" >
                                <option class="displayTextcss" *ngFor="let suppList of supplierList"
                                (click)="onItemSelection(suppList.description,suppList.appparentid,suppList.inactive)"
                                (keyup)="onItemSelection(suppList.description,suppList.appparentid,suppList.inactive)" 
                                [class.current-selection]="suppList.description===descrip">
                                {{suppList.description}} - <span *ngIf="suppList.inactive===false">Active</span> <span *ngIf="suppList.inactive!==false">Inactive</span>
                                
                                </option>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>

                </mat-tab>
                <mat-tab label="Standard IET" *ngIf="themeOwnerEligible">
                    <div class="row subHeading m-3" style="font-style: italic;">This tab is to add, modify, or delete standard IETs, and to copy
                        IETs from the previous year
                    </div>
                    <div class="d-flex flex-row m-3 pb-5">
                        <div class="col-md-6">
                            <div class="boxHeader commonFont">
                                <div class="d-flex ">
                                    <span class="ms-2 mt-2">STANDARD IETs </span>


                                </div>
                                <div class="p-2 cursorptr">
                                    <input type="text" class="form-control addsearch search-tmna "
                                        placeholder="Search here" [(ngModel)]="inputSearchStandardIET" [ngModelOptions]="{standalone: true}">
                                        <img class="ms-1" src="../assets/images/search.svg" alt="img" 
                                        style="position: absolute;right: 18px;top: 44px;">
                                </div>

                            </div>
                            <div class="boxISE me-1 p-2 mb-7 ">
                                <div class="accordion leftHeadBtn" id="accordionExample"
                                    *ngFor="let category of standardIETsAccordianValue;let i = index">
                                    <div class="tree" *ngIf="filteredStandardIETTitle.includes(category.title)">
                                        <div class="d-flex" [id]="'heading'+i">
                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                (click)="category.toggle =!category.toggle ;
                                                showBulletValue(category.standardietid,category);"
                                                [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                                aria-expanded="true" aria-controls="collapseOne">

                                                <img class="clpseBtn collapsesearchfilterBtn"
                                                    [ngClass]="{'active':category.toggle }" alt="img">
                                            </button>
                                            <span class="accordianTitle mt-1" (click)="selectedSubnode(category.title,'second');
                                                showBullet(category.standardietid,category);" (keyup)="selectedSubnode(category.title,'second');
                                                showBullet(category.standardietid,category);">
                                                 {{category.title}}</span>
                                        </div>
                                        <div [id]="'collapse'+i" class="collapse subBulletList show cursorptr"
                                            *ngFor="let item of category.content" data-bs-parent="#accordionExample"
                                            [ngClass]="{ 'show': category.toggle }"
                                            [attr.aria-labelledby]="'heading'+i" style="padding-left: 25px;">
                                            <div style="padding: 0; margin: 0" class="accordianTitle ms-5" style="font-size: 12px;color:grey; margin-top:10px;margin-bottom:10px;word-wrap: break-word;"
                                                (click)="selectedSubnode(item.bullet,'third');showIETBullet(item);" (keyup)="selectedSubnode(item.bullet,'third');
                                                showIETBullet(item);">
                                                {{parseHTMLContent(item.bullet)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="accordion-wrapper  ">
                                <div class="accordion mb-3" id="homeAccordianOne">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span class="commonFont mt-1">EDITING STANDARD IETs</span>
                                                <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                    id="accordion3Btn" accordianActive="true"
                                                    data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                    aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                    <img class="clpseBtn collapsesearchfilterBtn"
                                                        [ngClass]="{'active':isOpen}" alt="img">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#proposalHist">
                                    <div class="cardBody">
                                        <div class="accordion-main p-2 mb-5">
                                            <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                (selectedIndex)="selectedTabIndex"
                                                (selectedTabChange)="onTabChanged($event)">
                                                <mat-tab label="IET Properties">
                                                    <div class="row mt-3" *ngIf="showTab=='first'">
                                                        <div *ngIf="showTab =='first'" class="d-flex">
                                                            <label class="label-tmna mt-2 ms-2">Add IETs :
                                                                <input class="input-tmna ms-3" type="text" 
                                                                formControlName="iseTabNodeValueName"
                                                                    placeholder="Enter Name">
                                                            </label>

                                                            <button class="closeButton ms-3 mt-2"
                                                                style="width: fit-content; height: 35px;" (click)="addIET('standardIET')">ADD+</button>

                                                        </div>
                                                    </div>

                                                    <div class="row mt-3 ms-2" *ngIf="showTab=='second'">
                                                        <div class="d-flex flex-column">
                                                            <label class="label-tmna ">Title :
                                                                <input class="input-tmna ms-2 "
                                                                    type="text" style="width: 50%;"
                                                                    formControlName="iseTabNodeValue">
                                                            </label>
                                                        </div>
                                                        <div class="label-tmna mt-2 pb-2">Body</div>
                                                        <div class=""> 
                                                            <quill-editor class="quillEditorField" [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}]}" 
                                                                [styles]="{height: '100px'}" formControlName="isetabNodeExplanation"></quill-editor>
                                                        </div>
                                                        <div class="d-flex accordianTitle mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2 cursorptr" (click)="saveIET()">Save</a>
                                                            <a class="ms-2 cursorptr" (click)="addBullet('standardIET')">Add IET Bullet</a>
                                                            <a class="ms-3 cursorptr" (click)="deleteIET()">Delete IET</a>
                                                        </div>

                                                    </div>
                                                    <div class="row mt-3" *ngIf="showTab=='third'">
                                                        <div class="label-tmna ms-3">Bullet :</div>
                                                        <div class="row p-2 ms-1">                                                             
                                                            <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}]}" 
                                                                [styles]="{height: '100px'}" formControlName="iseTabSubNodeValue"></quill-editor>
                                                        </div>
                                                        <div class="d-flex accordianTitle mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-3" (click)="saveIETBullet()">Save</a>
                                                            <a class="ms-3" (click)="deleteIETBullet()">Delete IET Bullet</a>
                                                            <a class="ms-3" (click)="cancelIETBullet()">Cancel</a>
                                                        </div>
                                                    
                                                    </div>


                                                </mat-tab>

                                            </mat-tab-group>


                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="General Priorities" *ngIf="themeOwnerEligible">
                   <div class="row subHeading m-3" style="font-style: italic;">
                    This tab is to add, modify, or delete standard IETs, and to copy IETs from the previous year
                </div>
                <div class="d-flex flex-row m-3 pb-5">
                    <div class="col-md-6">
                        <div class="boxHeader commonFont">
                            <div class="d-flex ">
                                <span class="ms-2 mt-2">GENERAL PRIORITIES</span>


                            </div>
                            <div class="p-2">
                                <input type="text" class="form-control addsearch search-tmna "
                                    placeholder="Search here" [(ngModel)]="inputSearchGeneralPriorities" [ngModelOptions]="{standalone: true}">
                                    <img class="ms-1" src="../assets/images/search.svg" alt="img" 
                                    style="position: absolute;right: 18px;top: 44px;">
                            </div>

                        </div>
                        <div class="boxISE me-1 p-2 mb-7 ">
                            <div class="accordion leftHeadBtn" id="accordionExample"
                                *ngFor="let category of generalPrioritiesAccordianValue; let i = index">
                                <div class="tree" *ngIf="filteredGeneralPrioritiesTitle.includes(category.title)">
                                    <div class="d-flex" [id]="'heading'+i">

                                        <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                        (click)="category.toggle =!category.toggle;showGeneralIETBulletValue(category.generalpriorityietid,category);"
                                            [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                            aria-expanded="true" aria-controls="collapseOne">

                                            <img class="clpseBtn collapsesearchfilterBtn"
                                                [ngClass]="{'active':category.toggle }" alt="img">
                                        </button>
                                        <span class="accordianTitle mt-1" (click)="selectedSubnode(category.title,'second');
                                        showGeneralIETBullet(category.generalpriorityietid,category);" (keyup)="selectedSubnode(category.title,'second');
                                        showGeneralIETBullet(category.generalpriorityietid,category);"> {{category.title}}</span>
                                    </div>
                                    <div [id]="'collapse'+i" class="collapse subBulletList show cursorptr"
                                        *ngFor="let item of category.content" data-bs-parent="#accordionExample"
                                        [ngClass]="{ 'show': category.toggle }"
                                        [attr.aria-labelledby]="'heading'+i" style="padding-left: 25px;">
                                        <div style="padding: 0; margin: 0" class="tree2 ms-5" style="font-size: 12px;color:grey; margin-top:10px;margin-bottom:10px;word-wrap: break-word;"
                                            (click)="selectedSubnode(item.bullet,'third');showGeneralIETBulletList(item);" (keyup)="selectedSubnode(item.bullet,'third');
                                            showGeneralIETBulletList(item);">
                                            <div class="bulletTitle" *ngIf="item.title">
                                                {{item.title}}
                                            </div>
                                            <div class="bulletItem" [ngClass]="{'list': item.title}" [ngStyle]="{'margin-left': item.title ? '20px': ''}">
                                            {{parseHTMLContent(item.bullet)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="accordion-wrapper  ">
                            <div class="accordion mb-3" id="homeAccordianOne">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex flex-row justify-content-between">
                                            <span class="commonFont mt-1">EDITING GENERAL PRIORITIES</span>
                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                id="accordion3Btn" accordianActive="true"
                                                data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                <img class="clpseBtn collapsesearchfilterBtn"
                                                    [ngClass]="{'active':isOpen}" alt="img">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#proposalHist">
                                <div class="cardBody">
                                    <div class="accordion-main p-2 mb-5">
                                        <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                            (selectedIndex)="selectedTabIndex"
                                            (selectedTabChange)="onTabChanged($event)">
                                            <mat-tab label="General Priorities Properties">
                                                <div class="row mt-3" *ngIf="showTab=='first'">
                                                    <div *ngIf="showTab =='first'" class="d-flex">
                                                        <label class="label-tmna mt-2 ms-2">Add General IETs :
                                                            <input class="input-tmna ms-3" type="text" 
                                                            formControlName="iseTabNodeGeneralPrtyValueName"
                                                                placeholder="Enter Name">
                                                        </label>

                                                        <button class="closeButton ms-3 mt-2 cursorptr"
                                                            style="width: fit-content; height: 35px;" (click)="addIET('generalPriority')">ADD+</button>

                                                    </div>
                                                </div>

                                                <div class="row mt-3 ms-2" *ngIf="showTab=='second'">
                                                    <div class="d-flex flex-column">
                                                        <label class="label-tmna ">Title :
                                                            <input class="input-tmna ms-2 "
                                                                type="text" style="width: 50%;"
                                                                formControlName="iseTabNodeGeneralPrtyValue">
                                                        </label>
                                                    </div>
                                                    <div class="label-tmna mt-2 pb-2">Body</div>
                                                    <div class=""> 
                                                        <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}]}" 
                                                            [styles]="{height: '100px'}" formControlName="isetabNodeGeneralPrtyExplanation"></quill-editor>
                                                    </div>
                                                    <div class="d-flex accordianTitle mt-2"
                                                        style="text-decoration: underline;">
                                                        <a class="ms-2 cursorptr" (click)="saveGeneralIET()">Save</a>
                                                        <a class="ms-2 cursorptr" (click)="addBullet('generalPriority')">Add IET Bullet</a>
                                                        <a class="ms-3 cursorptr" (click)="deleteGeneralIET()">Delete IET</a>
                                                    </div>

                                                </div>
                                                <div class="row mt-3 ms-2" *ngIf="showTab=='third'">
                                                    <div class="d-flex flex-column">
                                                        <label class="label-tmna">Title :
                                                            <input class="input-tmna ms-2" style="width: 50%;"
                                                                type="text" formControlName="iseTabNodeGeneralPrtyBulletTitle">
                                                        </label>
                                                    </div>
                                                    <div class="label-tmna mt-2 pb-2">Bullet :</div>
                                                    <div class="">                                                         
                                                        <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}]}" 
                                                            [styles]="{height: '100px'}"formControlName="iseTabSubNodeGeneralPrtyValue"></quill-editor>
                                                    </div>
                                                    <div class="d-flex accordianTitle mt-2"
                                                        style="text-decoration: underline;">
                                                        <a class="ms-2 cursorptr" (click)="saveGeneralIETBullet()">Save</a>
                                                        <a class="ms-2 cursorptr" (click)="deleteGeneralIETBullet()">Delete IET Bullet</a>
                                                        <a class="ms-2 cursorptr" (click)="cancelGeneralIETBullet()">Cancel</a>
                                                    </div>
                                                
                                                </div>


                                            </mat-tab>

                                            <mat-tab label="User Permission">
                                                <div class="row mt-2 mb-3">
                                                    <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                        (selectedIndex)="selectedTabIndex"
                                                        (selectedTabChange)="onTabChanged($event)">
                                                        <mat-tab label="Theme Owners">
                                                            <div class="ms-3 mt-2 me-4">
                                                                <button class="closeButton allNoneBtn mb-2"  style="float: inline-start" (click)="allNoneThemeOwners()" (keyup)="allNoneThemeOwners()">ALL/NONE</button>
                                                                <button class="closeButton refreshBtn" style="float: inline-end" (click)="this.showThemeOwners()" (keyup)="this.showThemeOwners()">REFRESH</button>
                                                            </div>
                                                            <div class="ms-3">
                                                                <div class="boxHeader" style="height: 56px;">
                                                                    <div class="cursorptr m-2 d-flex" >
                                                                        <input type="text" class="form-control addsearch search-tmna "
                                                                        placeholder="Search here" [(ngModel)]="searchName" [ngModelOptions]="{standalone: true}">
                                                                        <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                                                                        style="position: absolute;right: 17px;top: 11px;">
                                    
                                                                    </div>
                                    
                                                                </div>
                                                                <div class="boxISE col">
                                                                    <div class="m-3" style="height: 200px; min-height: auto;">
                                                                        <div *ngIf="themeOwnersList.length > 0">
                                                                            <ul style="padding-left: 1rem;">
                                                                                <li class="label-tmna" *ngFor="let item of themeOwnersList; let i = index" style="list-style: none;">
                                                                                    <div class="d-flex" style="gap: 20px; padding-bottom: 20px;" 
                                                                                     *ngIf="filteredFullName.includes(themeOwnerfullname[i])">
                                                                                     <input type="checkbox" [checked]="item.is_theme_owner" style="box-shadow: none;"
                                                                                      (click)="onCheckboxUpdate(item.email, !item.is_theme_owner)" (keyup)="onCheckboxUpdate(item.email, !item.is_theme_owner)"/>
                                                                                     <span class="label-tmna" style="color: #161718;">
                                                                                         {{themeOwnerfullname[i]}}
                                                                                     </span>
                                                                                    </div>
                                                                                 </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mt-2">
                                                                    <button class="closeButton applyPermissionBtn" style="float: inline-start;" (click)="applyPermissions()" (keyup)="applyPermissions()">APPLY ROLE</button>
                                                                </div>
                                                            </div>
                                                        </mat-tab>
                                                    </mat-tab-group>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>


                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                </mat-tab>
                <mat-tab label="Supplier Category" *ngIf="isAuthenticUser">
                    <div class="row subHeading m-2">
                        <div class="d-flex justify-content-start">
                            <div class="me-5">
                                <span class="row subHeading mt-2" style="font-style: italic;">This tab is to add, modify/update TEMA Value Supplier Category
                                    options</span>
                            </div>
                            <div class="d-flex" style="float: left;">
                                
                                <button class="addButton ms-2" style="width: fit-content;"
                                (click)="addIT()">ADD+</button>

                            </div>
                        </div>
                        <div class="me-5">
                            <span class="row subHeading mt-1" style="font-weight: 600;color: #212529">NOTE: Do not duplicate with same data while adding.</span>
                        </div>
                    </div>
                     
                    <div class="flex-row p-1">
                        <ag-grid-angular #agGrid style="margin-bottom:50px;" class="ag-theme-balham " [rowData]="rowDataForImprovementTarget"
                            [columnDefs]="columnDefForImprovementTarget" (gridReady)="onGridReady($event, 'grid')" [pagination]="true"
                            headerHeight="50" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize" [groupDisplayType]="groupDisplayType"
                            [domLayout]="domLayout" [icons]="icons"  [rowGroupPanelShow]="'always'" [suppressDragLeaveHidesColumns]="true">

                        </ag-grid-angular>

                    </div>

                </mat-tab>
                <mat-tab label="Supplier Flag" *ngIf="isAuthenticUser">
                    <div class="row subHeading m-2">
                        <div class="d-flex justify-content-start">
                            <div class="me-5">
                                <span class="row subHeading mt-2" style="font-style: italic;">This tab is to add, modify/update APP/BRM flag options and descriptions</span>
                            </div>
                            <div class="d-flex" style="float: left;">
                                <button class="addButton ms-2" style="width: fit-content;"
                                (click)="addSupplierFlag()">ADD+</button>
                            </div>
                        </div>
                        <div class="me-5">
                            <span class="row subHeading mt-1" style="font-weight: 600;color: #212529">NOTE: Do not duplicate with same data while adding.</span>
                        </div>
                    </div>
                    <div class="flex-row p-1">
                        <ag-grid-angular #agGrid1 style="margin-bottom:50px;" class="ag-theme-balham " [rowData]="rowDataForSupplierFlag"
                            [columnDefs]="columnDefForSupplierFlag" (gridReady)="onGridReady($event, 'grid1')" [pagination]="true"
                            headerHeight="50" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationSupplierFlagScreen" [groupDisplayType]="groupDisplayType"
                            [domLayout]="domLayout" [icons]="icons"  [rowGroupPanelShow]="'always'" [suppressDragLeaveHidesColumns]="true">
                        </ag-grid-angular>
                    </div>
                </mat-tab>
                <mat-tab label="Apply Standard IET to Supplier" *ngIf="isAuthenticUser">
                    <div class="row m-3">
                        <div class="heading"> standard IETs</div>
                        <div class="subHeading">This tab is to Apply Standard IETs to Suppliers.</div>
                    </div>
                    <div class="m-3">
                        <div class="col-md-6">
                            <select class="standiet" id="standardIET" 
                            (change)="titleIET($event.target.value)" >
                                <option *ngFor="let IET of standardIET;" [value]="IET.title">{{IET.title}}</option>
                            </select>
                        </div>
                        <div class="col-md-6 p-2">
                            <div class="boxHeader commonFont" style="height:100px;">
                                <div class="d-flex justify-content-between ">
                                    <span class="ms-2 mt-1 ">STANDARD IETs </span>
                                    <button class="closeButton mt-1 me-2" style="width: fit-content;" (click)="allnoneIET()" >ALL/NONE</button>


                                </div>
                                <div class="p-2">
                                    <input type="text" class="form-control addsearch search-tmna "
                                        placeholder="Search here">
                                    <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                                        style="position: absolute;right: 17px;top: 60px;">
                                </div>

                            </div>
                            <div class="boxISE me-1 p-2  ">

                                <div class="displayTextcss" *ngFor="let suppList of suppliersList">
                                    <input type="checkbox" (click)="checkIET(suppList.description,!suppList.checked)"
                                    (keyup)="checkIET(suppList.description,!suppList.checked)"
                                    [checked]="suppList.checked" style="margin-right:10px;">{{suppList.description}}</div>

                            </div>
                            <div class="boxHeader justify-content-end  ">
                                <button class="closeButton ms-3 me-2 mt-2" style="width: fit-content;" (click)="titleIET(titleStandardIET)">Refresh</button>
                                <button class="addButton ms-3 ms-2 mt-2" style="width: fit-content;" (click)="applyStandardIET()">APPLY</button>

                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Emailer" *ngIf="isAuthenticUser">
                    <app-emailer></app-emailer>
                </mat-tab>
                <mat-tab label="Fiscal Year" *ngIf="isAuthenticUser">
                    <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                    (selectedIndex)="selectedTabIndex"
                    (selectedTabChange)="onTabChanged($event)" class="mt-3">
                   
                    <mat-tab label="Fiscal Year">
                        <div class="row mt-3 mb-2">
                            <div class="d-flex ms-2">
                                <label class="label-tmna mt-1 me-4" style="font-weight: bold;">Current Fiscal Year :
                                    <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.currentFiscalYear}}"
                                        style="width: 70px;"></label>
                            </div>
                            
                            <p class="label-tmna mt-1 me-4 ms-2">{{this.fiscalYearStatusMsg}}</p>
                            <p *ngIf="executeFiscalYearBtn" class="label-tmna mt-1 me-4 ms-3">Please navigate to <span style="font-weight:bold;">"Copy IETs from Previous Year"</span> tab for the next steps.</p>
                        </div>
                        <div class="row mb-3"
                            style="height: fit-content;">
                            <p class="label-tmna ms-2 text-wrap" style="font-weight: bold;">Please follow the below steps to create and copy current Fiscal year data to new Fiscal year: </p>
                            <p class="label-tmna ms-2 text-wrap steps"> Step 1: <button [disabled]="createNewFiscalYearBtn" class="closeButton ms-3 fiscalYearBtn" style="width: fit-content;float: none " (click)="changeFiscalYear()">Create New Fiscal Year</button></p>
                            
                          <div>

                            
                            <p class="label-tmna ms-2 text-wrap">This action will change the current APP fiscal
                                year to a new year({{this.newFiscalYear}}).</p>
                          </div>
                          <p class="label-tmna ms-2 text-wrap steps"> Step 2: <button [disabled]="populateSuppFiscalYearBtn" class="closeButton ms-3 fiscalYearBtn" disabled style="width: fit-content;float: none " (click)="fiscalYearCopyData('POPULATE')">POPULATE SUPPLIER LIST</button></p>
                          
                          <div>

                            
                            <p class="label-tmna ms-2 text-wrap">This can be ran after the APP fiscal
                                year is switched to a new year. It populates the supplier
                                list for the new year. This will also copy the APP flags
                                from the previous year. The standard IETs will also be
                                copied</p>
                          </div>
                          <p class="label-tmna ms-2 text-wrap steps"> Step 3: <button [disabled]="updateLeadBuyerFiscalYearBtn" class="closeButton ms-3  fiscalYearBtn" disabled style="width: fit-content;float: none " (click)="fiscalYearCopyData('UPDATELEADBUYER')">UPDATE APP LEAD BUYER</button></p>
                          
                          <p class="label-tmna ms-2 text-wrap mb-5">This will import and  update the lead buyers from previous year.</p>
                        </div>

                    </mat-tab>

                    <mat-tab label="Copy IETs from Previous Year">
                        <div class="row flex-row mt-3 mb-3">
                            <div class="col-md-6 d-flex">
                                <label class="label-tmna ms-2 pe-5"> Previous Year:
                                    <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.currentFiscalYear}}"
                                        style="width: 70px;">
                                </label>

                                <label class="label-tmna me-1"> Current Year: 
                                    <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.newFiscalYear}}"
                                        style="width: 70px;">
                                </label>

                            </div>
                        </div>

                        <div class="col-md-6 mt-3">
                            <div class="accordion-wrapper ms-2">
                                <div class="accordion" id="homeAccordianOne">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span class="commonFont mt-1">EDITING IETs</span>
                                                <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                    id="accordion3Btn" accordianActive="true"
                                                    data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                    aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                    <img class="clpseBtn collapsesearchfilterBtn"
                                                        [ngClass]="{'active':isOpen}" alt="img">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#proposalHist">
                                    <div class="cardBody" style="border-top: none;">
                                        <div class="accordion-main mb-4">
                                            <div class="row">
                                                <div class="d-flex m-3">
                
                                                    <form [formGroup]="selectedCopyIETs">
                                                        <div formArrayName="checkboxes" class="d-flex" style="gap: 10px;">
                                                          <div *ngFor="let checkbox of checkboxes; let i = index" class="pe-3">
                                                            <input type="checkbox" [formControlName]="i" (change)="onCheckboxChange($event, checkbox.value)">
                                                            <span class="label-tmna ps-1">{{ checkbox.label }}</span>
                                                          </div>
                                                        </div>
                                                      </form>
                                                </div>
                
                                            </div>

                                            <div class="boxHeaderForIET "
                                                style="height: 60px;">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <button class="addButton mt-2 ms-2 fiscalYearBtn" disabled 
                                                        style="width:100px;" [disabled]="executeFiscalYearBtn" (click)="fiscalYearCopyData('EXECUTE')">Execute</button>
                                                    </div>
                                                    <div style="visibility: hidden;">
                                                        toyota
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="row mb-6 mt-2">
                            <span class="label-tmna ms-2">
                                This is the process that will
                                copy over IETs and IET Bullets from last year. Check the
                                status of the IETs you would like to copy and click
                                Execute.</span>

                        </div>

                    </mat-tab>
                </mat-tab-group>
                   
                </mat-tab>
            </form>

        </mat-tab-group>

    </div>
    <div *ngIf="false">
        <div class=" container unauthorized"  >
          <p></p>
          <p style="color: red;">You do not have a role assigned to access this Application.</p>
          <p>Please raise a request using 
            <a style="color: #2688Da; text-decoration:underline" href="https://ep.idm.toyota.com/identityiq/home.jsf" >SailPoint</a>.
            Refer to the <span style="color: #2688Da; text-decoration:underline; cursor:pointer">User Guide</span>
            for more details.
          </p>
        </div>
      </div>


</div>