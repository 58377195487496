import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {
  public userToken : any;
  public loggedUserGUID:string="";
  public loggedUserName:string="";
  public loggedUserEmail:string="";
  public loggedUserRole:string;
  public loggedUserEmployeeId:number;
  public loggedUserBuyerCode:string;
  public loggedUserManagerCode:string;
  public loggedUserRoleRank: number;
  public themeOwner:any;
  public assumedUserGUID:string="";
  public assumedUserName:string="";
  public assumedUserEmail:string="";
  public assumedUserRole:string;
  public assumedUserEmployeeId:number;
  public assumedUserBuyerCode:string;
  public assumedUserManagerCode:string;
  public assumedUserRoleRank: number;
  


  constructor() { }
}
