import { Component, HostListener, OnInit, ViewChild, model } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ImprovementTargetEditComponent } from './improvement-target-edit/improvement-target-edit.component';
import { GridApi, GridOptions, RowGroupingDisplayType } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { LookupService } from '../services/lookup.service';

import { ImprovementTargetTitleComponent } from './improvement-target-title/improvement-target-title.component';
import { ImprovementTargetDescriptionComponent } from './improvement-target-description/improvement-target-description.component';
import { ImprovementTargetOrderComponent } from './improvement-target-order/improvement-target-order.component';
import { ImprovementTargetInactiveComponent } from './improvement-target-inactive/improvement-target-inactive.component';
import { AgGridAngular } from 'ag-grid-angular';
import { AdminService } from '../services/admin.service';
import 'ag-grid-enterprise';
import { SupplierFlagEditComponent } from './supplier-flag-edit/supplier-flag-edit.component';
import { SupplierFlagTitleComponent } from './supplier-flag-title/supplier-flag-title.component';
import { SupplierFlagOrderComponent } from './supplier-flag-order/supplier-flag-order.component';
import { SupplierFlagActiveComponent } from './supplier-flag-active/supplier-flag-active.component';
import { DropdownFloatingFilterComponent } from '../core/dropdown-floating-filter/dropdown-floating-filter.component';
import { BusinessEntityService } from '../services/business-entity.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('agGrid1') agGrid1: AgGridAngular;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  isOpen=true;
  showTab='first';
  selectedValue=false;
  selectedTabIndex=0;
  paginationPageSize=4;
  paginationSupplierFlagScreen = 10;
  domLayout='autoHeight';
  selected = model<Date | null>(null);
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: any;
  adminForm:FormGroup;
  selectedThemeOwner: any;
  themeOwnerfirstname: any[];
  themeOwnerlastname: any[];
  themeOwneremail: any[];
  themeOwnerfullname: any;
  textareaValue: any;
  searchName = '';
  selectedThemeOwnerEmail: string;
  successMessage='The Response should come from Backend';
  failureMessage='Some Error occured ! Please try after sometime';
  isAuthenticUser=false;
  isManager=false;
  applyPermissionsRequestbody: any;
  updatesThemePermissionsRequestBody: any = [];
  params: any;
  fiscalYearStatusMsg;
  createNewFiscalYearBtn = true;
  populateSuppFiscalYearBtn = true;
  updateLeadBuyerFiscalYearBtn  = true;
  executeFiscalYearBtn  = true;
  currentFiscalYear;
  newFiscalYear;
  selectedCopyIETs: FormGroup;
  checkboxes = [
    { label: 'Open', value: 'Open' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Pending', value: 'Pending' },
    { label: 'In-Progress', value: 'In-Progress' }
  ];
  copyIETselectedValues: string[] = [];
  gridApiMap = new Map<string, any>();
  standardIETTitleList = [];
  generalPrioritiesTitleList = [];
  standardIETsAccordianValueList = [];
  generalPrioritiesAccordianValueList = [];
  clickedIETAccordionId: any;
  clickedIETAccordionBulletList: any;
  clickedGeneralIETAccordionId: any;
  clickedGeneralIETAccordionBulletList: any;
  clickedIETTitleId: any;
  clickedGeneralIETTitleId: any;
  clickedIETBulletId: any;
  clickedGeneralIETBulletId: any;
  clickedAddStandardIETBullet: boolean;
  clickedAddGeneralIETBullet: boolean;
  
  onTabChanged(event: MatTabChangeEvent) {
   
  }
  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder
    ,private readonly lookup:LookupService, private readonly admin:AdminService,private readonly bservice:BusinessEntityService
    ){}
 ngOnInit():void{
  if(this.bservice?.loggedUserRole ==='PurappsManager' && this.bservice?.themeOwner ){
    this.isAuthenticUser=false;
    this.themeOwnerEligible = true;
  }else if(this.bservice?.loggedUserRole==='PurappsAPPAdmin'){
    this.isAuthenticUser=true;
    this.themeOwnerEligible = true;
  } 

  this.ngxSpinner.show();
  this.showFiscalYearTab();
  this.showISE();
  this.showIET();
  this.showImprovement();
  this.showSupplierFlagDetails(this.fiscalYear);
  this.showStandardIET();
  this.showGeneralPriorities();
  this.showThemeOwners();
  
  this.adminForm=this.formBuilder.group({
    iseTabNodeValueName:new FormControl(''),
    iseTabNodeValue:new FormControl(''),
    isetabNodeExplanation:new FormControl(''),
    iseTabSubNodeValue:new FormControl(''),
    iseTabNodeGeneralPrtyValueName:new FormControl(''),
    iseTabNodeGeneralPrtyValue:new FormControl(''),
    isetabNodeGeneralPrtyExplanation:new FormControl(''),
    iseTabSubNodeGeneralPrtyValue:new FormControl(''),
    iseTabNodeGeneralPrtyBulletTitle: new FormControl(''),
  })

  this.selectedCopyIETs = this.formBuilder.group({
    checkboxes: this.formBuilder.array(this.checkboxes.map(() => false))
  });
  setTimeout(()=> {
    this.ngxSpinner.hide();
  }, 2000);
 }

 inputSearchISE:any='';
 supplierList:any=[];
 supplierListISE:any=[];
  rowDataForImprovementTarget=[]
  rowDataForSupplierFlag=[];
  defaultColDef={ 
    suppressNavigable: true,
    suppressMovable: false,
    cellClass: 'no-border',
    enableRowGroup: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    minWidth: 180,
    suppressFloatingFilterButton: true,
    autoHeight: true
   };

   icons = {
    menu: ' ',
    filter: ' '
   }
   groupDisplayType: RowGroupingDisplayType = "groupRows";
   columnDefForImprovementTarget=[{
    headerName: 'SL.no',
    field: '',
    cellRenderer: ImprovementTargetEditComponent,
    minWidth: 40,
    cellRendererParams: {
      update: this.updateIT.bind(this),
      cancel: this.cancelIT.bind(this),
    },
    flex: 1,
    filter: false,
    enableRowGroup: false,
    lockPosition: true,
    },
    {
      headerName: 'Title',
      field: 'title',
      cellRenderer:ImprovementTargetTitleComponent,
      minWidth: 40,
      flex: 1,
    },
    {
      headerName: 'Target Description',
      field: 'targetdescription',
      cellRenderer:ImprovementTargetDescriptionComponent,
      minWidth: 400,
      flex: 4,
    },
    {
      headerName: 'Display Order',
      field: 'displayorder',
      cellRenderer:ImprovementTargetOrderComponent,
      minWidth: 40,
      flex: 1,
    },
    {
      headerName: 'Inactive?',
      field: 'inactive',
      cellRenderer:ImprovementTargetInactiveComponent,
      minWidth: 40,
      flex: 1,
      floatingFilterComponent: DropdownFloatingFilterComponent,
      floatingFilter: true,
      floatingFilterComponentParams: {
        associatedList: ['All','Yes','No']     
      },
      valueGetter: (params) => {
        return params.data?.inactive === true ? 'Yes' : 'No';
      }
    }
   ];

   columnDefForSupplierFlag=[{
    headerName: 'SL.no',
    field: '',
    filter: false,
    cellRenderer: SupplierFlagEditComponent,
    cellRendererParams: {
      update: this.updateSupplierFlag.bind(this),
      cancel: this.cancelSupplierFlag.bind(this),
    },
    flex: 1,
    enableRowGroup: false,
    lockPosition: true,
    },
    {
      headerName: 'Title',
      field: 'title',
      cellRenderer:SupplierFlagTitleComponent,
      flex: 2,
    },
    {
      headerName: 'Display Order',
      field: 'displayorder',
      cellRenderer:SupplierFlagOrderComponent,
      flex: 1,
    },
    {
      headerName: 'Active?',
      field: 'active',
      cellRenderer:SupplierFlagActiveComponent,
      flex: 1,
      floatingFilterComponent: DropdownFloatingFilterComponent,
      floatingFilter: true,
      floatingFilterComponentParams: {
        associatedList: ['All','Yes','No']
      },
      valueGetter: (params) => {
        return params.data?.active === true ? 'Yes' : 'No';
      }
    }
   ];

   addIT(){
    this.clearITFilter();
    const newRow=  this.agGrid?.api.applyTransaction({
      add: [{editable:true, title: '', targetdescription: '', displayorder: '', inactive: false }]
    });
    const rowdata=[]
    rowdata[0]=newRow;
    for(let i=1;i<=this.rowDataForImprovementTarget.length;i++){
      rowdata[i]=this.rowDataForImprovementTarget[i-1];
    }
    this.rowDataForImprovementTarget=rowdata;
    this.rowDataForImprovementTarget[0].editable=true;
    this.agGrid?.api.paginationGoToPage(0);
   }

   clearITFilter() {
    this.agGrid?.api.setFilterModel(null);
    this.agGrid?.api.getColumns().forEach((col) => {
      this.agGrid?.api.applyColumnState({
        state: [{ colId: col.getId(), sort: null }]
      });
    });
    this.agGrid?.api.setRowGroupColumns(null);
   }

   postITbody:any;
   putITbody:any;

   updateIT(params){
    if(params.data?.apptemavalueimprovementtargetid){
      this.putITbody={
        "title": params.data?.title,
        "targetdescription": params.data?.targetdescription,
        "displayorder": params.data?.displayorder,
        "Inactive": params.data?.inactive,
        "employeeId": sessionStorage.getItem("assumeEmployeeid"),
        "improvementTargetId": params.data?.apptemavalueimprovementtargetid
      }
      this.lookup.updateImprovementTarget(this.putITbody).subscribe(_data=>{
        this.showImprovement();
      })
    }
    else{
      let inactive=false;
      if(params.data?.inactive===true){
        inactive=true;
      }
      this.postITbody={
        "target": params.data?.title,
        "targetdescription": params.data?.targetdescription,
        "displayorder": params.data?.displayorder,
        "inactive": inactive,
        "createdbyemployeeid": sessionStorage.getItem("assumeEmployeeid")
      }
      this.lookup.addNewImprovementTarget(this.postITbody).subscribe(_data=>{
        this.showImprovement();
      })
    }
   }

   cancelIT(_params){
    this.showImprovement();
   }

   showImprovement(){
    this.lookup.getImprovementTarget().subscribe(data=>{
      this.rowDataForImprovementTarget=data.body.response;
    })
   }

  addSupplierFlag(){
    this.clearSupplierFlagFilter();
    const newRow=  this.agGrid1?.api.applyTransaction({
      add: [{editable:true, title: '', displayorder: '', active: false }]
    });
    const rowdata=[]
    rowdata[0]=newRow;
    for(let i=1;i<=this.rowDataForSupplierFlag.length;i++){
      rowdata[i]=this.rowDataForSupplierFlag[i-1];
    }
    this.rowDataForSupplierFlag=rowdata;
    this.rowDataForSupplierFlag[0].editable=true;
    this.agGrid1?.api.paginationGoToPage(0);
  }

  clearSupplierFlagFilter() {
    this.agGrid1?.api.setFilterModel(null);
    this.agGrid1?.api.getColumns().forEach((col) => {
      this.agGrid1?.api.applyColumnState({
        state: [{ colId: col.getId(), sort: null }]
      });
    });
    this.agGrid1?.api.setRowGroupColumns(null);
  }

  postSupplierFlagbody:any;
  putSupplierFlagbody:any;

  updateSupplierFlag(params){
    if(params.data?.appparentflagtemplateid){
      this.putSupplierFlagbody={
        "title": params.data?.title,
        "displayOrder": params.data?.displayorder,
        "active": params.data?.active,
        "updatedByEmployeeID": sessionStorage.getItem("assumeEmployeeid"),
        "aPPParentFlagTemplateID": params.data?.appparentflagtemplateid,
        "fiscalYearID": this.fiscalYear
      }
      this.lookup.appparentflagtemplateupdate(this.putSupplierFlagbody).subscribe(_data=>{
        this.showSupplierFlagDetails(this.fiscalYear);
      })
    }
    else{
      let active=false;
      if(params.data?.active===true){
        active=true;
      }
      this.postSupplierFlagbody={
        "title": params.data?.title,
        "displayOrder": params.data?.displayorder,
        "active": active,
        "createdByEmployeeID": sessionStorage.getItem("assumeEmployeeid"),
        "updatedByEmployeeID": sessionStorage.getItem("assumeEmployeeid"),
        "fiscalYearID": this.fiscalYear
      }
      this.lookup.appparentflagtemplateinsert(this.postSupplierFlagbody).subscribe(_data=>{
        this.showSupplierFlagDetails(this.fiscalYear);
      })
    }
  }

  cancelSupplierFlag(_params){
    this.showSupplierFlagDetails(this.fiscalYear);
  }

  showSupplierFlagDetails(fiscalYear: number){
    this.lookup.getSupplierFlags(fiscalYear).subscribe(data=>{
      this.rowDataForSupplierFlag=data.body.supplierFlag;
    })
   }

  descrip: string;
  apparentid: any;
  inactive: any;
  fiscalYear:any=41;
  onItemSelection(descrip: string,apparentid: any,inactive: any) {
    this.descrip = descrip;
    this.apparentid=apparentid;
    if(inactive===true){
      this.inactive=false;
    }
    else if(inactive===false){
      this.inactive=true;
    }
  }
  showISE(){
    this.lookup.getISESuppliers(this.fiscalYear).subscribe(data => {
      this.supplierList = data.body.response;
      this.supplierListISE = data.body.response;
      this.descrip = '';
      this.apparentid = '';
      this.inactive = '';
    })
  }
  toggle(){
    const togglebody={
      "parentId": this.apparentid,
      "inactive": this.inactive,
      "modifiedBy": sessionStorage.getItem("username")
    }
    this.lookup.toggleISESuppliers(togglebody).subscribe(_data=>{
      this.showISE();
    })
  }
  
  onGridReady(params:any, gridKey: string): void{
    this.gridApiMap.set(gridKey, params.api);
    params.api?.sizeColumnsToFit();
  }
  @HostListener("window:resize", ['$event'])
  onResize(): void {
    this.resizeAllGrids();
  }

  resizeAllGrids(): void {
    this.gridApiMap.forEach((gridApi) => {
      if(gridApi) {
        gridApi.sizeColumnsToFit();
        gridApi.refreshCells();
      }
    })
  }

  standardIETsAccordianValue:any=[]
  standardIETId:any;
  generalIETId: any;
  standardIETBulletId:any;
  generalIETBulletId: any;
  generalPrioritiesAccordianValue:any=[]
  generalPrioritiesTitle = [];
  standardIETTitle = [];
  inputSearchGeneralPriorities = '';
  inputSearchStandardIET = '';

  showIET(){
    this.standardIETsAccordianValue = [];
    this.standardIETTitle = [];
    this.lookup.getStandardIETList(this.fiscalYear).subscribe(data=>{
      this.standardIETsAccordianValueList=data.body.response;
      this.standardIETsAccordianValueList.sort((a,b) => a.standardietid - b.standardietid);
      for(const element of this.standardIETsAccordianValueList){
        if(element.title !== ""){
          this.standardIETsAccordianValue.push(element);
        }
      }
      this.standardIETTitleList = data.body.response.map(
        (value)=> value.title);
      for(const element of this.standardIETTitleList){
        if(element !== ""){
          this.standardIETTitle.push(element);
        }
      }
    })
  }

  showGeneralPriorities(){
    this.generalPrioritiesAccordianValue = [];
    this.generalPrioritiesTitle = [];
    this.lookup.getGeneralIETList(this.fiscalYear).subscribe(data=>{
      this.generalPrioritiesAccordianValueList=data.body.response;
      this.generalPrioritiesAccordianValueList.sort((a,b) => a.generalpriorityietid - b.generalpriorityietid);
      for(const element of this.generalPrioritiesAccordianValueList){
        if(element.title !== ""){
          this.generalPrioritiesAccordianValue.push(element);
        }
      }
      this.generalPrioritiesTitleList = data.body.response.map(
        (value)=> value.title);
      for(const element of this.generalPrioritiesTitleList){
        if(element !== ""){
          this.generalPrioritiesTitle.push(element);
        }
      }
    })
  }

  filteredISE(){
  if(this.inputSearchISE===''){
    this.supplierList=this.supplierListISE;
  }
  else
      this.supplierList=this.supplierList.filter((value) =>
      value.description.toLowerCase().includes(this.inputSearchISE.toLocaleLowerCase())
    );
  }

  get filteredGeneralPrioritiesTitle(): string[] {
    return this.generalPrioritiesTitle.filter((value) =>
      value.toLowerCase().includes(this.inputSearchGeneralPriorities.toLocaleLowerCase())
    );
  }

  get filteredStandardIETTitle(): string[] {
    return this.standardIETTitle.filter((value) =>
      value.toLowerCase().includes(this.inputSearchStandardIET.toLocaleLowerCase())
    );
  }

  showBullet(standardiet,category){
    this.clickedIETTitleId = standardiet;
    this.clickedAddStandardIETBullet = false;
    if(category.content){
      this.clickedIETAccordionBulletList = category.content?.map((id) => id.standardietbulletid)
    }
    this.adminForm.controls['iseTabNodeValue'].setValue(category.title);
    this.adminForm.controls['isetabNodeExplanation'].setValue(category.body);
    this.standardIETId=standardiet;
    this.lookup.getStandardIETBulletDesc(standardiet).subscribe(data=>{
      for(const element of this.standardIETsAccordianValue){
        if(element.standardietid===standardiet){
          element.content=data.body.response;
        }
      }
      
    })
  }

  showBulletValue(standardiet,_category){
    this.clickedIETAccordionId = standardiet;
    this.clickedAddStandardIETBullet = false;
    if(_category.content) {
      this.clickedIETAccordionBulletList = _category.content?.map((id) => id.standardietbulletid)
    }
    this.lookup.getStandardIETBulletDesc(standardiet).subscribe((data)=>{
      const targetElement = this.standardIETsAccordianValue.find((element) => element.standardietid === standardiet);
      if(targetElement) {
        targetElement.content = data.body.response.sort((a,b) => a.standardietbulletid - b.standardietbulletid);
      }
    });
  }

  showGeneralIETBullet(generaliet, category) {
    this.clickedGeneralIETTitleId = generaliet;
    this.clickedAddGeneralIETBullet = false;
    if(category.content){
      this.clickedGeneralIETAccordionBulletList = category.content?.map((id) => id.generalpriorityietbulletid)
    }
    this.adminForm.controls['iseTabNodeGeneralPrtyValue'].setValue(category.title);
    this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].setValue(category.body);
    this.generalIETId=generaliet;
    this.lookup.getGeneralIETBulletDesc(generaliet).subscribe(data=>{
      for(const element of this.generalPrioritiesAccordianValue){
        if(element.generalpriorityietid===generaliet){
          element.content=data.body.response;
        }
      }
      
    })
  }

  showGeneralIETBulletValue(generaliet,_category){
    this.clickedGeneralIETAccordionId = generaliet;
    this.clickedAddGeneralIETBullet = false;
    if(_category.content) {
      this.clickedGeneralIETAccordionBulletList = _category.content?.map((id) => id.generalpriorityietbulletid)
    }
    this.lookup.getGeneralIETBulletDesc(generaliet).subscribe(data=>{
      const targetElement = this.generalPrioritiesAccordianValue.find((element) => element.generalpriorityietid === generaliet);
      if(targetElement) {
        targetElement.content = data.body.response.sort((a,b) => a.generalpriorityietbulletid - b.generalpriorityietbulletid);
      }
    });
  }

  selectedSubnode(value,block){
     this.showTab=block
  }

  addIETBody:any;
  updateIETBody:any;
  deleteIETBody:any;
  addIETBulletBody:any;
  updateIETBulletBody:any;
  deleteIETBulletBody:any;
  addIET(value){
    if(value === 'standardIET') {
      setTimeout(() => {
      this.adminForm.controls['iseTabNodeValue'].setValue(this.adminForm.controls['iseTabNodeValueName'].value)
      }, 1000);
      this.showTab='second';
    } else if(value === 'generalPriority') {
      setTimeout(() => {
        this.adminForm.controls['iseTabNodeGeneralPrtyValue'].setValue(this.adminForm.controls['iseTabNodeGeneralPrtyValueName'].value)
        }, 1000);
        this.showTab='second';
    }
  }

  saveIET(){
    if(!this.standardIETId){
    this.addIETBody={
      "fiscalYear": this.fiscalYear,
      "title": this.adminForm.controls['iseTabNodeValue'].value,
      "ietDescription": this.adminForm.controls['isetabNodeExplanation'].value,
      "inactive": true,
      "createdBy": sessionStorage.getItem("username"),
      "iet_type": "STANDARD"
    }
    if(this.addIETBody.title !== "") {
      this.lookup.addIET(this.addIETBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETId=false;
      })
      }
      this.showTab='first';
    }
    else{ 
    this.updateIETBody={
      "title": this.adminForm.controls['iseTabNodeValue'].value,
      "body": this.adminForm.controls['isetabNodeExplanation'].value,
      "modifiedBy": sessionStorage.getItem("username"),
      "iet_Id": this.standardIETId,
      "iet_type": "STANDARD"
    }
    this.lookup.updateIET(this.updateIETBody).subscribe(_data=>{
      this.showIET();
      this.adminForm.reset();
      this.showTab='first';
      this.standardIETId=false;
    })
    }
  }

  deleteIET(){
    this.deleteIETBody={
      "iet_Id": this.standardIETId,
      "iet_type": "STANDARD"
    }
    this.lookup.deleteIET(this.deleteIETBody).subscribe(_data=>{
      this.showIET();
      this.adminForm.reset();
    })
  }

  saveGeneralIET(){
    if(!this.generalIETId){
    this.addIETBody={
      "fiscalYear": this.fiscalYear,
      "title": this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value,
      "ietDescription": this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value,
      "inactive": true,
      "createdBy": sessionStorage.getItem("username"),
      "iet_type": "GENERAL"
    }
    if(this.addIETBody.title !== "") {
      this.lookup.addIET(this.addIETBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETId=false;
      })
      }
      this.showTab='first';
    }
    else{ 
    this.updateIETBody={
      "title": this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value,
      "body": this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value,
      "modifiedBy": sessionStorage.getItem("username"),
      "iet_Id": this.generalIETId,
      "iet_type": "GENERAL"
    }
    this.lookup.updateIET(this.updateIETBody).subscribe(_data=>{
      this.showGeneralPriorities();
      this.adminForm.reset();
      this.showTab='first';
      this.generalIETId=false;
    })
    }
  }

  deleteGeneralIET(){
    this.deleteIETBody={
      "iet_Id": this.generalIETId,
      "iet_type": "GENERAL"
    }
    this.lookup.deleteIET(this.deleteIETBody).subscribe(_data=>{
      this.showGeneralPriorities();
      this.adminForm.reset();
    })
  }

  addBullet(value){
    if(value === 'standardIET') {
      this.standardIETBulletId = '';
      this.showTab='third';
      this.adminForm.controls['iseTabSubNodeValue'].setValue("");
      this.clickedAddStandardIETBullet = true;
    } else if(value === 'generalPriority') {
      this.generalIETBulletId = '';
      this.showTab='third';
      this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue("");
      this.clickedAddGeneralIETBullet = true;
    }
  }

  showIETBullet(item){
    this.clickedIETBulletId = item.standardietbulletid;
    this.standardIETBulletId=item.standardietbulletid;
    this.adminForm.controls['iseTabSubNodeValue'].setValue(item.bullet);
  }

  showGeneralIETBulletList(item) {
    this.clickedGeneralIETBulletId = item.generalpriorityietbulletid;
    this.generalIETBulletId = item.generalpriorityietbulletid;
    this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].setValue(item.title);
    this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue(item.bullet);
  }

  saveIETBullet(){
    if(!this.standardIETBulletId){
      this.addIETBulletBody={
        "iet_Id": this.standardIETId,
        "bulletDescription": this.adminForm.controls['iseTabSubNodeValue'].value,
        "createdBy": sessionStorage.getItem("username"),
        "iet_type": "STANDARD",
        "title": this.adminForm.controls['iseTabNodeValue'].value
      }
      if(this.addIETBulletBody.bulletDescription !== "") {
        this.lookup.addIETBullet(this.addIETBulletBody).subscribe(_data=>{
          this.showIET();
          this.adminForm.reset();
          this.showTab='first';
          this.standardIETBulletId=false;
        })
      }
      this.showTab='first';
      }
      else{ 
      this.updateIETBulletBody={
        "bullet": this.adminForm.controls['iseTabSubNodeValue'].value,
        "modifiedBy": sessionStorage.getItem("username"),
        "ietbullet_id": this.standardIETBulletId,
        "title": this.adminForm.controls['iseTabNodeValue'].value,
        "iet_type": "STANDARD"
      }
      this.lookup.updateIETBullet(this.updateIETBulletBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETBulletId=false;
      })
      }
  }

  deleteIETBullet(){
    this.deleteIETBulletBody={
      "ietbullet_id": this.standardIETBulletId,
      "iet_type": "STANDARD"
    }
    this.lookup.deleteIETBullet(this.deleteIETBulletBody).subscribe(_data=>{
      this.showIET();
      this.adminForm.reset();
    })
  }

  saveGeneralIETBullet(){
    if(!this.generalIETBulletId){
      this.addIETBulletBody={
        "iet_Id": this.generalIETId,
        "bulletDescription": this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value,
        "createdBy": sessionStorage.getItem("username"),
        "iet_type": "GENERAL",
        "title": this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value
      }
      if((this.addIETBulletBody.bulletDescription !== "") && (this.addIETBulletBody.title !== "")) {
        this.lookup.addIETBullet(this.addIETBulletBody).subscribe(_data=>{
          this.showGeneralPriorities();
          this.adminForm.reset();
          this.showTab='first';
          this.generalIETBulletId=false;
        })
      }
      this.showTab = 'first';
      }
      else{ 
      this.updateIETBulletBody={
        "bullet": this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value,
        "modifiedBy": sessionStorage.getItem("username"),
        "ietbullet_id": this.generalIETBulletId,
        "title": this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value,
        "iet_type": "GENERAL"
      }
      this.lookup.updateIETBullet(this.updateIETBulletBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETBulletId=false;
      })
      }
  }

  deleteGeneralIETBullet(){
    this.deleteIETBulletBody={
      "ietbullet_id": this.generalIETBulletId,
      "iet_type": "GENERAL"
    }
    this.lookup.deleteIETBullet(this.deleteIETBulletBody).subscribe(_data=>{
      this.showGeneralPriorities();
      this.adminForm.reset();
    })
  }

  cancelIETBullet(){
    if ((this.clickedIETTitleId === this.clickedIETAccordionId) || this.clickedAddStandardIETBullet) {
      if(this.clickedIETAccordionBulletList?.includes(this.clickedIETBulletId)) {
        this.selectedSubnode('', 'second');
      } else if(this.clickedAddStandardIETBullet) {
        this.clickedAddStandardIETBullet = false;
        this.selectedSubnode('', 'second');
      } else {
        this.selectedSubnode('', 'first');
      }
    } else {
      this.selectedSubnode('', 'first');
    }
  }

  cancelGeneralIETBullet() {
    if ((this.clickedGeneralIETTitleId === this.clickedGeneralIETAccordionId) || this.clickedAddGeneralIETBullet) {
      if(this.clickedGeneralIETAccordionBulletList?.includes(this.clickedGeneralIETBulletId)) {
        this.selectedSubnode('', 'second');
      }else if(this.clickedAddGeneralIETBullet) {
        this.clickedAddGeneralIETBullet = false;
        this.selectedSubnode('', 'second');
      } else {
        this.selectedSubnode('', 'first');
      }
    } else {
      this.selectedSubnode('', 'first');
    }
  }

  themeOwnersList = [];
  selectAllThemeOwners = true;
  themeOwnerEligible = false;

  showThemeOwners() {
    this.updatesThemePermissionsRequestBody = [];
    this.lookup.getManagerNames().subscribe(data=>{
      this.themeOwnersList=data.body.response;
      this.themeOwnerfullname = this.themeOwnersList.map(value=> `${value.firstname} ${value.lastname}`);
    });
  }

  allNoneThemeOwners() {
    this.updatesThemePermissionsRequestBody = [];
    if(this.selectAllThemeOwners){
      for(const element of this.themeOwnersList){
        element.is_theme_owner=true;
      }
      this.tickThemeOwnerCheckbox(true);
    } else if (!this.selectAllThemeOwners){
      for(const element of this.themeOwnersList){
        element.is_theme_owner=false;
      }
      this.tickThemeOwnerCheckbox(false);
    }
    this.selectAllThemeOwners = !this.selectAllThemeOwners;
  }

  tickThemeOwnerCheckbox(check) {
    for (const element of this.themeOwnersList) {
      const themeOwnersChecked = {};
      themeOwnersChecked['upn'] = element.email;
      themeOwnersChecked['is_theme_owner'] = check;
      this.updatesThemePermissionsRequestBody.push(themeOwnersChecked);
    }
  }

  onCheckboxUpdate(email, check) {
    for(const element of this.themeOwnersList){
      let copy=false;
      if(element.email===email){
        element.is_theme_owner=check;
        const themeOwnersChecked = {};
        themeOwnersChecked['upn'] = element.email;
        themeOwnersChecked['is_theme_owner'] = element.is_theme_owner;
        for(const element of this.updatesThemePermissionsRequestBody){
          if(element.email===themeOwnersChecked['upn']){
            copy=true;
            element.is_theme_owner=check;
          }
        }
        if(copy!==true){
          this.updatesThemePermissionsRequestBody.push(themeOwnersChecked);
        }
      }
    }
  }
    
  applyPermissions() {
    this.applyPermissionsRequestbody={
      "updates":this.updatesThemePermissionsRequestBody
    }
    this.admin.getapplyPermission(this.applyPermissionsRequestbody).subscribe((_data) =>{})
  }

  get filteredFullName(): string[] {
    return this.themeOwnerfullname.filter((val) => 
      val.toLowerCase().includes(this.searchName.toLocaleLowerCase()))
  }

  parseHTMLContent(value: string) {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(value, 'text/html');
    return parsedHTML.body.textContent || '';
  }

  updatesStandardIETBody:any=[];
  applyStandardIETBody:any;
  standardIET:any;
  suppliersList = [];
  titleStandardIET:any;
  titleStandIET:any;
  toggleStandardIET:any=true;
  showStandardIET(){
    this.lookup.getStandardIETNamesInCurrentFY().subscribe(data=>{
      this.standardIET=data.body.response;
      this.titleIET(this.standardIET[0].title);
    })
  }
  
  titleIET(title){ 
    this.updatesStandardIETBody=[];
    this.titleStandardIET=title;
    this.lookup.getSuppliersByIETName(this.titleStandardIET).subscribe(data=>{
      this.suppliersList=data.body.response;
    })
  }

  checkIET(description,checked){
    for(const element of this.suppliersList){
      let copy=false;
      if(element.description===description){
        element.checked=checked;
          const supplist = {};
          supplist['suppliername'] = element.description;
          supplist['checked'] = element.checked;
          supplist['ietname'] = this.titleStandardIET;
          for(const element of this.updatesStandardIETBody){
            if(element.suppliername===supplist['suppliername']){
              copy=true;
              element.checked=checked;
            }
          }
          if(copy!==true){
          this.updatesStandardIETBody.push(supplist);
          }
      }
    }
  }

  allnoneIET(){
    this.updatesStandardIETBody=[];
    if(this.toggleStandardIET){
      for(const element of this.suppliersList){
        element.checked=true;
      }
      this.tickIET(true);
    }
    else{
      for(const element of this.suppliersList){
        element.checked=false;
      }
      this.tickIET(false);
    }
    this.toggleStandardIET=!this.toggleStandardIET;
  }

  tickIET(check){
    for (const element of this.suppliersList) {
      const supplist = {};
      supplist['suppliername'] = element.description;
      supplist['checked'] = check;
      supplist['ietname'] = element.ietname;
      this.updatesStandardIETBody.push(supplist);
    }
  }

  applyStandardIET(){
    this.applyStandardIETBody={"updates":this.updatesStandardIETBody
    }
    this.lookup.applyStandardIET(this.applyStandardIETBody).subscribe(_data=>{
    })
  }

  showFiscalYearTab() {
    this.ngxSpinner.show();
    //** */
    const fiscalyearBody = {
      employeeId: sessionStorage.getItem('assumeEmployeeid')
    };
    this.lookup.getSupplierList(fiscalyearBody).subscribe((Fiscaldata) => {
      this.currentFiscalYear = Number(Fiscaldata['body']['fiscalYear'][0]['year']);
      this.newFiscalYear = this.currentFiscalYear+1;
    });
    this.admin.fiscalYearStatus().subscribe((data) => {
      if(data['statusCode'] === 200){
        const runningFiscalYear = data['body'][0];
        this.fiscalYearStatusMsg = `NOTE: New fiscal year created by ${ runningFiscalYear['triggered_by']} on ${ runningFiscalYear['triggered_datetime'] }. Current Status: ${ runningFiscalYear['status'] }`;
        //Disable all
        this.createNewFiscalYearBtn = true;
        this.populateSuppFiscalYearBtn = true;
        this.updateLeadBuyerFiscalYearBtn = true;
        this.executeFiscalYearBtn = true;
        if(runningFiscalYear['status'] === "Completed")
        {
          this.createNewFiscalYearBtn = false;
          this.executeFiscalYearBtn = false;
        }else if(runningFiscalYear['stage_status'] === "Completed"){
          if (runningFiscalYear['next_stage'] === "POPULATE"){
            this.populateSuppFiscalYearBtn = false;
          }else if(runningFiscalYear['next_stage'] === "UPDATELEADBUYER"){
            this.updateLeadBuyerFiscalYearBtn = false;
          }else if(runningFiscalYear['next_stage'] === "EXECUTE"){
            this.executeFiscalYearBtn = false;
          }
        }
      }else if(data['statusCode'] === 204){
        this.createNewFiscalYearBtn = false;
      }
      this.ngxSpinner.hide();
    });
  }

  /** */
  changeFiscalYear() {
    const fiscalYearParams = {
      currentFiscalYear: this.currentFiscalYear,
      employeeName: sessionStorage.getItem("username")
    }

    this.admin.changeFiscalYear(fiscalYearParams).subscribe((_data)=> {
      /** */
      this.showFiscalYearTab();
    });
  }

  onCheckboxChange(event: any, value: string) {
    if (event.target.checked) {
      this.copyIETselectedValues.push(value);
    } else {
      const index = this.copyIETselectedValues.indexOf(value);
      if (index > -1) {
        this.copyIETselectedValues.splice(index, 1);
      }
    }
  }

  /** */
  fiscalYearCopyData(stage) {
    let SPParam = null;
    if(stage === "EXECUTE"){
      SPParam = this.copyIETselectedValues;
    }
    const copyDataFiscalYear = {
      stage: stage,
      currentFiscalYear: this.currentFiscalYear,
      newFiscalYear: this.newFiscalYear,
      params: SPParam
    }

    this.admin.updateFiscalYear(copyDataFiscalYear).subscribe((_data)=> {
      this.showFiscalYearTab();
    })
  }
  
}
