import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
 
@Component({
  selector: 'app-improvement-target-description',
  templateUrl: './improvement-target-description.component.html',
  styleUrl: './improvement-target-description.component.css'
})
export class ImprovementTargetDescriptionComponent implements ICellRendererAngularComp  {
  // refresh(_params: ICellRendererParams): boolean {
  //   throw new Error('Method not implemented.');
  // }
  refresh(){
    return false;
  }
  params;

  agInit(params): void {
    this.params = params;
  }
}
