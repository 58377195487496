import { Component, HostListener, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GridApi } from 'ag-grid-community';
import { IseSupplierCodeRendererComponent } from './ise-supplier-code-renderer/ise-supplier-code-renderer.component';
import { IseIetTitleRendererComponent } from './ise-iet-title-renderer/ise-iet-title-renderer.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-ise',
  templateUrl: './ise.component.html',
  styleUrl: './ise.component.css'
})
export class IseComponent implements OnInit{
  isOpen1=true;
  isOpen2=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  paginationPageSize=4;
  leadBuyerEmail='joe@toyota.com';
  selectedTabIndex=0;
  breadcrumbItems=[{
    label: 'Home',
    link: '/home',
    icon: '>'
  },
  {
    label: 'View/edit ISE',
    link: null,
    icon: ''
  },];
  showIETSection = true;
  gridApiMap = new Map<string, any>();


  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
   };

   columnDefSupplierContacts=[
    {
      headerName: 'Supplier Division',
      field: 'supplierdivision',
      flex: 1
    },
    {
      headerName: 'WARP Contact Type',
      field: 'warpcontacttype',
      flex: 1
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Title',
      field: 'title',
      flex: 1
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1
    }
  ]

   rowDataSupplierContacts=[];

   columnDefSuppliers = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      cellRenderer: IseSupplierCodeRendererComponent,
      flex: 1
    },
    {
      headerName: 'Supplier Name',
      field: 'suppliername',
      flex: 2
    }
  ]

   rowDataSuppliers = [];

  columnDefIndividualExpectedTasks=[
    {
    headerName: 'Title',
    field: 'name',
    cellRenderer: IseIetTitleRendererComponent,
    minWidth: 120,
    flex: 2
    },
    {
      headerName: 'Body',
      field: 'description',
      minWidth: 400,
      flex: 5
    },
    {
      headerName: 'Date Entered',
      field: 'dateentered',
      minWidth: 100,
      flex: 1,
      cellRenderer: (params) => {
        if(params.value){
        const isoformattedDate = params.value.replace(' ', 'T');
        const parsedDate = new Date(isoformattedDate);
        return new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(parsedDate);
        
        }
      }
    },
    {
      headerName: 'Status',
      field: 'ietorder',
      minWidth: 60,
      flex: 1,
      cellRenderer: (params) => {
        if(params.value===0){
         return 'Open';
        }
        else if(params.value===1){
          return 'In progress';
         }
        else if(params.value>=2){
          return 'Closed';
         }
      }
    }
  ]

  rowDataIndividualExpectedTasks=[]
  iseForm:FormGroup;

  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly lookup:LookupService,
    private readonly router: Router,private readonly formBuilder:FormBuilder ){}

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.iseForm=this.formBuilder.group({
      description:new FormControl(''),
      purchasing:new FormControl('')
    })
    this.iseForm.controls.description.disable();
    this.iseForm.controls.purchasing.disable();
     this.showIndividualExpectedTask();
     this.showISEDetails();
    setTimeout(()=>{
      this.ngxSpinner.hide();
    }, 2000);
  }


  showIndividualExpectedTask(){
    this.lookup.getIndividualExpectedTask(sessionStorage.getItem("appParentId")).subscribe(data=>{
      this.rowDataIndividualExpectedTasks=data.body.response;
    })
  }

  showISEDetails(){
    this.lookup.getISEDetails(sessionStorage.getItem("appParentId"),sessionStorage.getItem("assumeEmployeeid")).subscribe(data=>{
      this.rowDataSupplierContacts=data.body.response.appSupplierDivisionContact;
      this.rowDataSuppliers=data.body.response.appSupplierPlants;
      this.iseForm.controls['description'].setValue(data.body.response.appISEDetails[0].description);
      this.iseForm.controls['purchasing'].setValue(data.body.response.appISEDetails[0].name);
      this.leadBuyerEmail=data.body.response.appISEDetails[0].leadbuyeremail;
    })
  }
  
  onTabChanged(event: MatTabChangeEvent) {
    
  }

  openHomeScreen() {
    this.router.navigate(['/home']);
  }

  onGridReady(params:any, gridKey: string): void{
    this.gridApiMap.set(gridKey, params.api);
    params.api.sizeColumnsToFit();
  }
  @HostListener("window:resize", ['$event'])
  onResize(): void {
    this.resizeAllGrids();
  }

  resizeAllGrids(): void {
    this.gridApiMap.forEach((gridApi) => {
      if(gridApi) {
        gridApi.sizeColumnsToFit();
        gridApi.refreshCells();
      }
    })
  }
}
