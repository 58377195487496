<form [formGroup]="emailForm">
    <div class="mt-3" style="padding-bottom: 190px; padding-right: 10px;">
        <div class="d-flex flex-row">
            <div class="col-5 col-md-5 me-1">
                <ag-grid-angular  class="ag-theme-balham me-4" [rowData]="rowDataForEmailer"  [columnDefs]="columnDefForEmailer"
                    headerHeight="50" [defaultColDef]="defaultColDef" [domLayout]="domLayout"
                    [floatingFiltersHeight]="50" style="margin-right: 0px !important;"
                    [rowSelection]="rowSelection" (selectionChanged)="onSelection()" [icons]="icons"
                    (gridReady)="onGridReady($event)" >
                </ag-grid-angular>
            </div>
            <div class="col-7 col-md-7 ms-1">
                <span class="emailListPreviewHeader"> EMAIL LIST PREVIEW DISPLAY </span>
                <div class="cardBody mt-2 pb-2 pt-1">
                    <div class="d-flex flex-column m-2 ps-1">
                        <div class="d-flex flex-row m-1 pb-2">
                            <div class="d-flex flex-column">
                                <div class=""><label for="fiscalYear" class="label-tmna"> Fiscal Year: </label></div>
                                <div class=""> 
                                    <input class="input-tmna me-2" formControlName="fiscalYear" type="text" style="width: 75%" placeholder="Enter Fiscalyear">  
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <div class=""><label for="dueDate" class="label-tmna"> Due Date: </label></div>
                                <div class=""> 
                                    <input class="input-tmna me-2" formControlName="dueDate" type="date" style="width: 80%" placeholder="Due Date">  
                                </div>
                            </div>
                            <button type="button" class="addButton ms-3 mt-4" (click)="setfy()" [disabled]="setfydisable" style="width: fit-content; height: 35px; float: none; text-transform: none;">Set Fiscal Year </button>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex m-1 pb-2">
                                <div class="col-md-4"><label for="supplierFilter" class="label-tmna">Filter:</label></div>
                                <div class="col-md-8">
                                    <select id="supplierFilter" class="dropdown-tmna ms-1" formControlName="filter" (change)="changeFilter($event.target.value)">
                                        <option class="displayTextcss" *ngFor="let item of supplierFilterData" [value]="item.value">
                                            {{item.filterdata}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex m-1 pb-1">
                                <div class="col-md-4"><label for="supplierFlag" class="label-tmna">Supplier Flag:</label></div>
                                <div class="col-md-8">
                                    <select id="supplierFlag" class="dropdown-tmna ms-1" formControlName="supplierFlag">
                                        <option class="displayTextcss" *ngFor="let item of supplierFlagData" [value]="item.title">
                                            {{item.title}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="reportDetails">
                        <div class="d-flex m-2 pb-2">
                            <div class="col-md-2"><label for="emailBody" class="label-tmna ms-2" >Email Body:</label></div>
                            <div class="col-md-10 ms-2"> 
                                <quill-editor class="quillEditorField" [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}]}" 
                                [styles]="{height: '100px'}" formControlName="emailBody"></quill-editor>
                            </div>
                        </div>
                        <div class="d-flex m-2 pb-2">
                            <div class="col-md-2"><label for="emailBodySurveyLink" class="label-tmna ms-2">Email Body for Survey Link:</label></div>
                            <div class="col-md-10 ms-2 d-flex flex-column" style="gap: 10px;"> 
                                <textarea class="label-tmna-big" formControlName="emailBodySurveyLink" style="width: 90%;" type="text" placeholder="Email body survey link" rows="2"></textarea>
                                <div class="d-flex">
                                    <input type="checkbox" formControlName="excludeSurvey" style="margin-right: 10px;box-shadow: none;"> <span class="label-tmna">Exclude survey link</span>
                                </div>
                                <span class="attachmentTooltip">
                                    Add an Attachment with the Browser… and add it by clicking “Save Content”. You can remove an Attachment by checking an Attachment and clicking “Remove Att”. You are limited to an individual file size of 5MB
                                </span>
                            </div>
                        </div>
                        <div class="d-flex m-2 pb-2">
                            <div class="col-md-2">
                                <label for="attachment" class="label-tmna ms-2">Attachment:</label>
                            </div>
                            <div class="col-md-10 ms-2">
                                <div class="d-flex attachingFiles">
                                    <div class="d-flex file-item" style="padding-bottom: 10px; gap: 10px;" *ngFor = "let file of files; let i = index">
                                        <input type="checkbox" class="shadow-none" [(ngModel)]="file.isSelected" [ngModelOptions]="{standalone: true}" (change)="onCheckboxSelected()"/>
                                        <div class="attachmentSection">
                                            <label for="attachedFileName" class="label-tmna">{{file.name}}</label>
                                            <button type="button" class="btn-close" (click)="removeAttachment(i)" (keyup)="removeAttachment(i)">
                                                <img src="\assets\images\close-icon.png" height="15" width="15" alt="Remove Attachment">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex" style="gap: 10px;">
                                    <button class="closeButton" (click)="fileInput.click()" style="width: fit-content;">ATTACH FILE</button>
                                    <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;"/>
    
                                    <span class="label-tmna pt-2 noFilesChosen" *ngIf="!isAnyFilesChosen">No Files Chosen</span>
    
                                    <button class="closeButton" style="width: fit-content;" (click)="saveEmail()">SAVE EMAIL CONTENT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-2 pb-2">
                        <div class="ms-2 recipientsToolTipContent">
                            <span class="label-tmna">
                                Test email recipients. ** Please use comma(,) to separate emails.
                            </span>
                            <textarea class="label-tmna-big" formControlName="recipients" placeholder="Test email recipients content" style="width: 95%;" rows="2"></textarea>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg justify-content-between footer-tab fixed-bottom sendTypeNav mt-2 col-md-12" aria-label="email send type" style="bottom: 60px;">
        <div class="ms-3 mb-2">
            <div class="sendResendDetails">
                <div class="d-flex flex-column flex-wrap me-3" style="gap: 5px">
                    <div class="flex-row">
                        <label for="lastSend" class="label-tmna">Last Send:</label>
                        <span class="label-tmna">{{lastsend}}</span>
                    </div>
                     
                    <div class="flex-row">
                        <label for="lastSendTest" class="label-tmna">Last Send Test:</label>
                        <span class="label-tmna">{{lastsendtest}}</span>
                    </div>
                </div>
                <div class="d-flex flex-column flex-wrap ms-3" style="gap: 5px;">
                    <div class="flex-row">
                        <label for="lastReend" class="label-tmna">Last Resend:</label>
                        <span class="label-tmna">{{lastresend}}</span>
                    </div>
                        
                    <div class="flex-row">
                        <label for="lastResendTest" class="label-tmna">Last Resend Test:</label>
                        <span class="label-tmna">{{lastresendtest}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="saveFooter mb-3 d-flex flex-row">
            <label for="sendType" style="padding-right: 30px; font-weight: 500;">Send Type: </label>
            <div class="d-flex" style="gap: 10px;">
                <input type="checkbox" class="shadow-none mb-1" formControlName="test" ><span class="label-tmna">Test</span>
                <label for="send" class="label-tmna me-2">
                    <input type="radio" name="emailRadio" formControlName="emailRadio" value="send" class="shadow-none"> Send
                </label>
                <label for="resend" class="label-tmna me-2">
                    <input type="radio" name="emailRadio" formControlName="emailRadio" value="resend" class="shadow-none"> Resend
                </label>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg justify-content-end footer-tab fixed-bottom mt-2" aria-label="send email">
        <div class="saveFooter mb-3 ">
            <button class="addButton ms-3 me-2" (click)="send()" style="width: fit-content;">SEND EMAIL</button>
        </div>
    </nav>
    </form>