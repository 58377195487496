import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { LookupService } from '../services/lookup.service';
import { SupplierCodeRendererComponent } from './supplier-code-renderer/supplier-code-renderer.component';
import { ManagementService } from '../services/management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessEntityService } from '../services/business-entity.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('listBox') listBox !: ElementRef<HTMLDivElement>;
  currentYear = new Date().getFullYear();
  appRefDocLink = "https://myteams.toyota.com/:f:/r/sites/PSDDivisionTeam/Shared%20Documents/Project%20Management/Annual%20Activity%20Files/APP/CY2025%20-%20FY26/FY26%20APP%20Reference%20Docs?csf=1&web=1&e=Bns1jX";
  isOpen1 = true;
  isOpen2 = true;
  isOpen3 = true;
  isOpen4 = true;
  gridApi: GridApi;
  gridColumnApi: any;
  paginationPageSize = 6;
  domLayout = 'autoHeight';
  totalPacket = 0;
  approvedpacket = 0;
  packetNotSent = 0;
  openPacket = 0;
  approvedpacketPercentage = 0;
  packetNotSentPercentage = 0;
  openPacketPercentage = 0;
  isStyled = false;
  tab1 = true;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  supplierListReqestBody: { employeeId: number }; //Setting the default parameter until authentication is done
  updateImprvCostTrgtRequestBody: {
    appParentId: number;
    fiscalId: number;
    targetId: number;
    imprvTrgt: string;
    aprCost: string;
    empId: number;
  };
  supplierList = [];
  supplierListResponse: any;
  selectedIndex: number | null;
  selectedFilterIndex: number | null;
  appParentId: any;
  fiscalyear: number;
  fiscalyearid: number;
  currentFiscalYear: number;
  previousFiscalYear: number;
  empId: number;
  selectedSupplierBuyerInfo: any;
  selectedAppParentId: any;
  combinedData = [];
  commodityCodeDetails: any;
  commodityDetails: any;
  prevMBEachievement = '';
  currentMBEachievement = '';
  prevWBEachievement = '';
  currentWBEachievement = '';
  improvementTargetData = [];
  supplierTierIIData: any;
  currentAPRtarget = '';
  currentAPRtargetValue = 0;
  prevAPRtarget = '';
  minoritytarget = '';
  wbetarget = '';
  selectedImprTargetTitle = '';
  selectedImprTargetDesc = '';
  searchSupplier = '';
  totalPacketPercentage = 0;
  selectedTargetId: any;
  suppInfo: any;
  dateApproved = '';
  doNotSendRequestBody: {
    appParentId: number,
    fiscalId: number,
    empId: number,
    isApproved: boolean
    doNotSendFlag: boolean
  };
  approveDisapproveRequestBody: {
    appParentId: number,
    fiscalId: number,
    empId: number,
    isApproved: boolean
    doNotSendFlag: boolean
  };
  approved: any;
  donotsend: any;
  showAllActionBtns: boolean;
  supplierListFlag = false;
  springFallLabelYear: any;
  tmnaYearLabel: any;
  isDisplay: boolean;
  loginUserName: any;
  loginUserRole: any;
  validation: any;
  displayUnauthorised:boolean;
  gridApiMap = new Map<string, any>();
  isManager = false;
  isAdmin = false;
  isBuyer = false;
  rowDataSafetyRisk = [];

  defaultColDef = {
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    resizable: true,
    minWidth: 60,
    autoHeight: true,
    wrapText: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    autoHeaderHeight: true,
    wrapHeaderText: true
  };

  columnDefsSafetyRisk = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      flex: 1,
    },
    {
      headerName: 'Target',
      field: 'target',
      flex: 1,
    },
    {
      headerName: 'Risk Rating',
      field: 'risk_rating',
      flex: 1,
    },
  ];

  rowDataCommodityDetails = [];

  columDefCommodityDetails = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      cellRenderer: SupplierCodeRendererComponent,
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: 'Commodity',
      field: 'description',
      flex: 4,
      minWidth: 240,
    },
    {
      headerName: 'Quality',
      children: [
        {
          headerName: 'OE',
          field: 'oedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Service',
          field: 'servicedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Prototype',
          field: 'prototypedefecttarget',
          flex: 1,
        },
        {
          headerName: 'S-rank QR',
          field: 'srankqpr',
          flex: 1,
        },
      ],
    },
    {
      headerName: 'Delivery KPM',
      field: 'deliverykpm',
      flex: 1,
    },
    {
      headerName: 'Warranty PPM',
      field: 'warrantytarget',
      flex: 1,
    },
  ];

  employeeId: any;
  disableISEReportsLink = false;
  selectedSupplierIndex: number;
  selectedTabValue: number;

  constructor(
    private readonly lookup: LookupService,
    private readonly management: ManagementService,
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly bService:BusinessEntityService
  ) {}
  
  ngOnInit() {
    this.validation=this.lookup.localStorageData.subscribe(data=>{
      console.log(data)
      if(data?.includes('success')){
        this.isDisplay=true;
        this.loginUserName = this.bService.loggedUserName;
        this.loginUserRole = this.bService.loggedUserRole;
        if(this.bService.loggedUserRole==='PurappsAPPAdmin'){
          this.loginUserRole='Admin';
          this.isAdmin = true;
        }else if(this.bService.loggedUserRole==='PurappsBuyer'){
          this.loginUserRole='Buyer';
          this.isBuyer = true;
        }else if(this.bService.loggedUserRole==='PurappsManager'){
          this.loginUserRole='Manager'
          this.isManager = true;
        }
        sessionStorage.setItem('UserEmail',this.bService.loggedUserEmail);
        sessionStorage.setItem('userRole',this.loginUserRole);
        sessionStorage.setItem('userName',this.bService.loggedUserName);
        sessionStorage.setItem('userGUID',this.bService.loggedUserGUID);
        sessionStorage.setItem('userToken',this.bService.userToken);

      }
      else if(data?.includes('failure')) {
        console.log(data)
        this.isDisplay=false;
        this.displayUnauthorised=true;
      }
    })
    
    this.springFallLabelYear = undefined;
    this.tmnaYearLabel = undefined;
    sessionStorage.setItem('page', 'home');
    this.employeeId = sessionStorage.getItem('assumeEmployeeid');
    this.fiscalyearid = Number(sessionStorage.getItem("fiscalId"));
    //Selecting the first supplier on load of Home screen or selecting previously selected supplier, when loading home screen from other page
    this.selectedSupplierIndex = Number(sessionStorage.getItem("indexBeforeLeaving"));
    this.selectedTabValue = Number(sessionStorage.getItem("selectedTab"));
    if(this.selectedSupplierIndex !== 0) {
      this.selectedFilterIndex = this.selectedIndex = this.selectedSupplierIndex;
    } else {
      this.selectedFilterIndex = this.selectedIndex = 0;
    }
    //Getting the details of previously selected supplier value, when returning from other screen
    if(this.selectedTabValue === 1){
      this.getSupplierlistDetail(this.selectedIndex);
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
    } else if(this.selectedTabValue === 2){
      this.getApprovedSupplierlistDetail(this.selectedIndex);
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
      this.tab4 = false;
    } else if(this.selectedTabValue === 3){
      this.getNotSentSupplierlistDetail(this.selectedIndex);
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
      this.tab4 = false;
    } else if(this.selectedTabValue === 4){
      this.getOpenSupplierlistDetail(this.selectedIndex);
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = true;
    } else {
      this.getSupplierlistDetail(this.selectedIndex);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.selectedSupplierFocus(), 1000);
  }

  selectedSupplierFocus() {
    if(this.listBox && this.selectedIndex !== null) {
      const listItem = this.listBox.nativeElement.querySelectorAll('.list-item');
      const selectedItem = listItem[this.selectedIndex];
      if(selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        })
      }
    }
  }

  toggleClass() {
    this.isStyled = !this.isStyled;
  }

  applyActiveClass(p, event: Event) {
    Number(sessionStorage.setItem("selectedTab",p));  //Storing the manually selected tab
    if (event.type === 'keyup' || event.type === 'click') {
      if (p === 1) {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.getSupplierlistDetail(0);
      } else if (p === 2) {
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;
        this.tab4 = false;
        this.getApprovedSupplierlistDetail(0); //On change of Tab, setting the index to first element as default
      } else if (p === 3) {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;
        this.tab4 = false;
        this.getNotSentSupplierlistDetail(0);
      } else if (p === 4) {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = true;
        this.getOpenSupplierlistDetail(0);
      }
    }
  }
  
  onGridReady(params:any, gridKey: string): void{
    this.gridApiMap.set(gridKey, params.api);
    params.api?.sizeColumnsToFit();
  }
  @HostListener("window:resize", ['$event'])
  onResize(): void {
    this.resizeAllGrids();
  }

  resizeAllGrids(): void {
    this.gridApiMap.forEach((gridApi) => {
      if(gridApi) {
        gridApi.sizeColumnsToFit();
        gridApi.refreshCells();
      }
    })
  }

  getSupplierlistDetail(originalIndex?: number) {
    this.supplierListReqestBody = {
      employeeId: this.employeeId,
    };

    if(this.supplierList) {
      this.lookup
      .getSupplierList(this.supplierListReqestBody)
      .subscribe((data) => {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
        this.supplierListResponse = data;
        this.supplierList = data.body.supplierList.map(
          (value) => value.description
        );
        this.appParentId = data.body.supplierList.map(
          (value) => value.appparentid
        );
        //disabling view/edit ISE and view reports link if supplierList is empty
        if(this.supplierList.length <= 0) {
          this.disableISEReportsLink = true;
        }else {
          this.disableISEReportsLink = false;
        }
        this.fiscalyearid =
        this.supplierListResponse.body.fiscalYear[0].fiscalyearid;
        sessionStorage.setItem("fiscalId",this.supplierListResponse.body.fiscalYear[0].fiscalyearid)
        this.fiscalyear =
          this.supplierListResponse.body.fiscalYear[0].year;
        this.currentFiscalYear = this.fiscalyear % 100;
        this.previousFiscalYear = (this.fiscalyear - 1) % 100;
        this.springFallLabelYear = this.fiscalyear - 1 ;
        this.tmnaYearLabel = this.currentFiscalYear + 1;
        this.empId = this.supplierListReqestBody.employeeId;
        if (this.supplierListResponse.body.packetApprovalInfo) {
          this.totalPacket =
            this.supplierListResponse.body.packetApprovalInfo[0]
              .activepacketcount ?? 0;
          this.approvedpacket =
            this.supplierListResponse.body.packetApprovalInfo[0]
              .approvedpacketcount ?? 0;
          this.openPacket =
            this.supplierListResponse.body.packetApprovalInfo[0]
              .openpacketcount ?? 0;
          this.packetNotSent =
            this.supplierListResponse.body.packetApprovalInfo[0]
              .notsendingpacketcount ?? 0;
          this.calculatePercentage();
        }
        //Setting the search filter index on click of Save/ Approve/ Do not send buttons
        if (this.supplierList && this.supplierList.length > 0 
          && (originalIndex > 0)) {  
          this.supplierListFlag = true;
          this.selectedFilterIndex = originalIndex;
        }
        if(originalIndex === 0) {
          this.selectedIndex = this.selectedFilterIndex = 0;
        }
        this.getSelectedSupplierDetails(this.selectedIndex);
    });
    }
  }
  
  getApprovedSupplierlistDetail(originalIndex?: number) {
    if(this.supplierList) {
      this.lookup
        .getApprovedAPPPackets(this.employeeId)
        .subscribe((data) => {
          this.supplierListResponse = {};
          this.supplierList.length = 0;
          this.supplierListResponse = data;
          this.supplierList = data.body.response.approvedSuppliers?.map(
            (value) => value.description
          );
          this.appParentId = data.body.response.approvedSuppliers?.map(
            (value) => value.appparentid
          );

          //disabling view/edit ISE and view reports link if supplierList is empty
          if(this.supplierList.length <= 0) {
            this.disableISEReportsLink = true;
          }else {
            this.disableISEReportsLink = false;
          }
          if (this.supplierListResponse.body.response.packetInfo) {
            this.totalPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .activepacketcount ?? 0;
            this.approvedpacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .approvedpacketcount ?? 0;
            this.openPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .openpacketcount ?? 0;
            this.packetNotSent =
            this.supplierListResponse.body.response.packetInfo[0]
              .notsendingpacketcount ?? 0;
            this.calculatePercentage();
          }

          //Setting the search filter index on click of Save/ Approve/ Do not send buttons
          if (this.supplierList && this.supplierList.length > 0 
            && (originalIndex > 0)) {  
            this.supplierListFlag = true;
            this.selectedFilterIndex = originalIndex;
          }
          if(originalIndex === 0) {
            this.selectedIndex = this.selectedFilterIndex = 0;
          }
          this.getSelectedSupplierDetails(this.selectedIndex);
      });
    }
  }

  getNotSentSupplierlistDetail(originalIndex?: number) {
    if(this.supplierList) {
      this.lookup
        .getNotSentAPPPackets(this.employeeId)
        .subscribe((data) => {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
          this.supplierListResponse = data;
          this.supplierList = data.body.response.noSendSuppliers?.map(
            (value) => value.description
          );
          this.appParentId = data.body.response.noSendSuppliers?.map(
            (value) => value.appparentid
          );

          //disabling view/edit ISE and view reports link if supplierList is empty
          if(this.supplierList.length <= 0) {
            this.disableISEReportsLink = true;
          }else {
            this.disableISEReportsLink = false;
          }

          if (this.supplierListResponse.body.response.packetInfo) {
            this.totalPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .activepacketcount ?? 0;
            this.approvedpacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .approvedpacketcount ?? 0;
            this.openPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .openpacketcount ?? 0;
            this.packetNotSent =
            this.supplierListResponse.body.response.packetInfo[0]
              .notsendingpacketcount ?? 0;
            this.calculatePercentage();
          }
        
          //Setting the search filter index on click of Save/ Approve/ Do not send buttons
          if (this.supplierList && this.supplierList.length > 0 
            && (originalIndex > 0)) {  
            this.supplierListFlag = true;
            this.selectedFilterIndex = originalIndex;
          }
          
          if(originalIndex === 0) {
            this.selectedIndex = this.selectedFilterIndex = 0;
          }
          this.getSelectedSupplierDetails(this.selectedIndex);
      });
    }
  }

  getOpenSupplierlistDetail(originalIndex?: number) {
    if(this.supplierList) {
      this.lookup
        .getOpenAPPPackets(this.employeeId)
        .subscribe((data) => {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
          this.supplierListResponse = data;
          this.supplierList = data.body.response.openAPPPacketSuppliers?.map(
            (value) => value.description
          );
          this.appParentId = data.body.response.openAPPPacketSuppliers?.map(
            (value) => value.appparentid
          );

          //disabling view/edit ISE and view reports link if supplierList is empty
          if(this.supplierList.length <= 0) {
            this.disableISEReportsLink = true;
          }else {
            this.disableISEReportsLink = false;
          }

          if (this.supplierListResponse.body.response.packetInfo) {
            this.totalPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .activepacketcount ?? 0;
            this.approvedpacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .approvedpacketcount ?? 0;
            this.openPacket =
            this.supplierListResponse.body.response.packetInfo[0]
              .openpacketcount ?? 0;
            this.packetNotSent =
            this.supplierListResponse.body.response.packetInfo[0]
              .notsendingpacketcount ?? 0;
            this.calculatePercentage();
          }
          
          //Setting the search filter index on click of Save/ Approve/ Do not send buttons
          if (this.supplierList && this.supplierList.length > 0 
            && (originalIndex > 0)) {  
            this.supplierListFlag = true;
            this.selectedFilterIndex = originalIndex;
          }
          if(originalIndex === 0) {
            this.selectedIndex = this.selectedFilterIndex = 0;
          }
          this.getSelectedSupplierDetails(this.selectedIndex);
      });
    }
  }
  
  getSelectedSupplierDetails(originalIndex?:number) {
    this.ngxSpinner.show();
    if (this.supplierList && this.supplierList.length > 0) {
      if(originalIndex) {
        this.selectedAppParentId = this.appParentId[originalIndex];
      } else {
        this.selectedAppParentId = this.appParentId[this.selectedIndex];
      }
      sessionStorage.setItem("appParentId",this.selectedAppParentId)
      this.selectedSupplierFocus();
      this.lookup
        .getSftyRiskCommodity(
          this.selectedAppParentId,
          this.fiscalyearid,
          this.employeeId
        )
        .subscribe((data) => {
          this.rowDataSafetyRisk = data.body.safetyRisk;
          this.commodityCodeDetails = data.body.commodityCode;
          this.commodityDetails = data.body.commodities;
          this.commodityData();
        });

      this.lookup
        .getSupplierTierResult(
          this.selectedAppParentId,
          this.fiscalyearid,
          this.employeeId
        )
        .subscribe((data) => {
          //improvement target data
          this.improvementTargetData = data.body.imprvtTarget;
          //selecting the first title once logged in//
          this.selectedImprTargetTitle = this.improvementTargetData[0].title;
          this.onSelectingImprvtTargetTitle();

          //tierII data
          if (data.body.supplierTier2 && data.body.supplierTier2.length > 0) {
            this.currentAPRtarget =
              data.body.supplierTier2[0].vipmanuimpraprtarget ?? '0';
            this.prevAPRtarget = data.body.supplierTier2[0].previoustarget ?? '0';
            this.minoritytarget =
              data.body.supplierTier2[0].tieriiminoritysourcingtarget ?? '0';
            this.wbetarget = data.body.supplierTier2[0].tieriiwbetarget ?? '0';
          } else {
            this.currentAPRtarget =
              this.prevAPRtarget =
              this.minoritytarget =
              this.wbetarget =
                '0';
          }
          //result data
          if (
            data.body.resultPercentage &&
            data.body.resultPercentage.length > 0
          ) {
            this.prevMBEachievement =
              data.body.resultPercentage[0].pastmbeachievement ?? '';
            this.currentMBEachievement =
              data.body.resultPercentage[0].currentmbeachievement ?? '';
            this.prevWBEachievement =
              data.body.resultPercentage[0].pastwbeachievement ?? '';
            this.currentWBEachievement =
              data.body.resultPercentage[0].currentwbeachievement ?? '';
          } else {
            this.prevMBEachievement =
              this.currentMBEachievement =
              this.prevWBEachievement =
              this.currentWBEachievement =
                '';
          }

          // Selected Supplier Info Details
          this.suppInfo = data.body.suppInfo;
          this.supplierInfoMethod();
        });
    }
    setTimeout(() => {
      this.ngxSpinner.hide();
    }, 2000);
  }
  
  saveSupplierDetails() {
    this.updateImprvCostTrgtRequestBody = {
      appParentId: this.selectedAppParentId,
      fiscalId: this.fiscalyearid,
      targetId: this.selectedTargetId,
      imprvTrgt: this.selectedImprTargetDesc,
      aprCost: this.currentAPRtarget,
      empId: this.employeeId
    };

    this.management
      .getUpdateImprvCostTrgt(this.updateImprvCostTrgtRequestBody)
      .subscribe((_data) => {
        if(this.tab1 === true){
          this.getSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab2 === true) {
          this.getApprovedSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab3 === true) {
          this.getNotSentSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab4 === true) {
          this.getOpenSupplierlistDetail(this.selectedFilterIndex);
        }
      });
  }

  doNotSendSupplierDetails() {
    this.doNotSendRequestBody = {
      appParentId: this.selectedAppParentId,
      fiscalId: this.fiscalyearid,
      empId: this.employeeId,
      isApproved: this.approved,
      doNotSendFlag: true //setting doNotSendFlag as true
    };
    this.management
      .getApproveDoNotSend(this.doNotSendRequestBody)
      .subscribe((_data) => {
        if(this.tab1 === true){
          this.getSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab2 === true) {
          this.getApprovedSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab3 === true) {
          this.getNotSentSupplierlistDetail(this.selectedFilterIndex);
        } else if (this.tab4 === true) {
          this.getOpenSupplierlistDetail(this.selectedFilterIndex);
        }
      });
  }

  approveDisapproveSupplierDetails() {
    if (this.approved) {
      this.approveDisapproveRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        empId: this.employeeId,
        isApproved: false, //setting isApproved as false //Disapprove button
        doNotSendFlag: false
      };
      this.management
        .getApproveDoNotSend(this.approveDisapproveRequestBody)
        .subscribe((_data) => {
          if(this.tab1 === true){
            this.getSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab2 === true) {
            this.getApprovedSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab3 === true) {
            this.getNotSentSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab4 === true) {
            this.getOpenSupplierlistDetail(this.selectedFilterIndex);
          }
        });
    } else {
      this.approveDisapproveRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        empId: this.employeeId,
        isApproved: true, //setting isApproved as true //Approve button
        doNotSendFlag: false
      };
      this.management
        .getApproveDoNotSend(this.approveDisapproveRequestBody)
        .subscribe((_data) => {
          if(this.tab1 === true){
            this.getSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab2 === true) {
            this.getApprovedSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab3 === true) {
            this.getNotSentSupplierlistDetail(this.selectedFilterIndex);
          } else if (this.tab4 === true) {
            this.getOpenSupplierlistDetail(this.selectedFilterIndex);
          }
        });
    }
  }

  calculatePercentage() {
    this.approvedpacketPercentage = Number(
      ((this.approvedpacket / this.totalPacket) * 100).toFixed(2)
    );
    this.openPacketPercentage = Number(
      ((this.openPacket / this.totalPacket) * 100).toFixed(2)
    );
    this.packetNotSentPercentage = Number(
      ((this.packetNotSent / this.totalPacket) * 100).toFixed(2)
    );
    this.totalPacketPercentage =
      this.approvedpacketPercentage +
      this.openPacketPercentage +
      this.packetNotSentPercentage;
  }

  get filteredSupplier(): string[] {
    return this.supplierList?.filter((supplier) =>
      supplier.toLowerCase().includes(this.searchSupplier.toLocaleLowerCase())
    );
  }

  selectSupplier(index: number) {
    //On selecting a supplier from the list we have to fetch all details in the page
    this.selectedFilterIndex = index;
    this.selectedIndex = this.mapFilterIndexToOriginal(index);
    sessionStorage.setItem("indexBeforeLeaving",this.selectedIndex.toString());
    this.getSelectedSupplierDetails(this.selectedIndex);
  }

  mapFilterIndexToOriginal(filteredIndex) {
    return this.supplierList.findIndex((supplier) => supplier === this.filteredSupplier[filteredIndex]);
  }

  resetSelection() {
    if(this.selectedFilterIndex !== null && (!this.filteredSupplier[this.selectedFilterIndex] || this.filteredSupplier[this.selectedFilterIndex] !== this.supplierList[this.selectedIndex])) {
      this.clearSelection();
    }
  }

  clearSelection() {
    this.selectedIndex = null;
    this.selectedFilterIndex = null;
  }

  onSearchChange() {
    this.resetSelection();
  }

  supplierInfoMethod() {
    if(this.suppInfo && this.suppInfo.length > 0) {
      this.selectedSupplierBuyerInfo = this.suppInfo[0].supplier;
      this.approved = this.suppInfo[0].approved;
      sessionStorage.setItem("supplierapproved",this.approved);
      this.donotsend = this.suppInfo[0].donotsend;
      this.dateApproved = this.suppInfo[0].dateapproved ?? '';
      this.showAllActionBtns = true;
      //Approved date and time
      if(this.dateApproved !== '') {
        const isoformattedDate = this.dateApproved.replace(' ', 'T');
        const parsedDate = new Date(isoformattedDate);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).format(parsedDate);
        this.dateApproved = formattedDate;
      }
    } else {
      this.selectedSupplierBuyerInfo = '';
      this.dateApproved = '';
      this.approved = false;
      this.donotsend = false;
      sessionStorage.setItem("supplierapproved",this.approved);
      this.showAllActionBtns = false;
    }
    
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const currentAPRInput = inputElement?.value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if(regex.test(currentAPRInput)) {
      this.currentAPRtargetValue = Number(currentAPRInput);
    }
  }

  allowOnlyValidChar(event: KeyboardEvent): void {
    const regex = /^[0-9.]$/;
    if(!regex.test(event.key) || (event.key === '.' && this.currentAPRtargetValue.toString().includes('.'))) {
      event.preventDefault();
    }
  }

  onFocusOut() {
    const currentAPRValue = this.currentAPRtargetValue || 0;
    if(currentAPRValue > 999) {
      this.currentAPRtarget = '999';
    }
     else{
      this.currentAPRtarget = this.currentAPRtargetValue.toString();
    }
  }

  commodityData() {
    if (this.commodityCodeDetails && this.commodityCodeDetails.length > 0) {
      this.rowDataCommodityDetails = this.commodityCodeDetails.map(
        (commodity) => {
          //matching values based on appid
          const description =
            this.commodityDetails.find((val) => val.appid === commodity.appid)
              ?.description || '';
          return {
            suppliercode: commodity.suppliercode,
            description: description,
            oedefecttarget: commodity.oedefecttarget,
            servicedefecttarget: commodity.servicedefecttarget,
            prototypedefecttarget: commodity.prototypedefecttarget,
            srankqpr: commodity.srankqpr,
            deliverykpm: commodity.deliverykpm,
            warrantytarget: commodity.warrantytarget,
            appId: commodity.appid,
            appParentId: commodity.appparentid,
            fiscalId: this.fiscalyearid,
          };
        }
      );
    }
  }

  onSelectingImprvtTargetTitle() {
    const selected = this.improvementTargetData.find(
      (item) => item.title === this.selectedImprTargetTitle
    );
    this.selectedImprTargetDesc = selected ? selected.targetdescription : '';
    this.selectedTargetId = selected?.apptemavalueimprovementtargetid;
  }
  
  ngOnDestroy() {
    sessionStorage.removeItem('page');
  }
}
