import { Component, HostListener } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { ManagementService } from '../services/management.service';
import { Router } from '@angular/router';
import { BusinessEntityService } from '../services/business-entity.service';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrl: './supplier-details.component.css'
})
export class SupplierDetailsComponent {
  commodityForm:FormGroup;
  isOpen1=true;
  isOpen2=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  disableform:any;
  isManager = false;
  isAdmin = false;
  isBuyer = false;

  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder
    ,private readonly lookup:LookupService, private readonly management:ManagementService,
    private readonly router: Router, private readonly bService: BusinessEntityService
    ){}
 ngOnInit():void{
  this.ngxSpinner.show();
  this.commodityForm=this.formBuilder.group({
    targetOE:new FormControl(''),
    targetService:new FormControl(''),
    goalPrototype:new FormControl(''),
    deliveryKPM:new FormControl(''),
    warrantyTarget:new FormControl(''),
  })

  if(sessionStorage.getItem('role') ==='PurappsManager') {
    this.isManager = true;
  }else if(sessionStorage.getItem('role')==='PurappsAPPAdmin') {
    this.isAdmin = true;
  } else if ( sessionStorage.getItem('role') ==='PurappsBuyer') {
    this.isBuyer = true;
  }

  this.getCommodities();
  this.disableCommodity();
  setTimeout(()=>{
    this.ngxSpinner.hide();
  },2000);
 }

 disableCommodity(){
  if(sessionStorage.getItem("supplierapproved")==="true" && this.isAdmin) {
    this.disableform=true;
    this.commodityForm.controls["targetOE"].disable();
    this.commodityForm.controls["targetService"].disable();
    this.commodityForm.controls["goalPrototype"].disable();
  } else if (!this.isAdmin) {
    this.disableform=true;
    this.commodityForm.controls["targetOE"].disable();
    this.commodityForm.controls["targetService"].disable();
    this.commodityForm.controls["goalPrototype"].disable();
    this.commodityForm.controls["deliveryKPM"].disable();
    this.commodityForm.controls["warrantyTarget"].disable();
  }
 }
  
  breadcrumbItems=[
    {
      label: 'Home',
      link: '/home',
      icon: '>'
    },
    {
      label: 'View/edit ISE',
      link: '/ise',
      icon: '>'
    },
    {
      label: sessionStorage.getItem("suppliercode"),
      link: null,
      icon: ''
    },
  ];

  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
   };

   columnDefCommodities=[
    {
      headerName: 'Code',
      field: 'commoditycode',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'commoditydescription',
      flex: 1
    },
    {
      headerName: 'Exclude',
      field: 'inactive',
      flex: 1,
      cellRenderer: (params) => {
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.checked = params.value;
        checkbox.addEventListener('change', (event) => {
          const isChecked = (event.target as HTMLInputElement).checked;
          params.setValue(isChecked);
        });
        for(const element of this.commodities){
          if(element.code===params.data.commoditycode){
            element.exclude=params.value;
          }
        }
        return checkbox;
      }
    }
  ];

  rowDataCommodities=[];
  qualityCommodityBody:any;
  commodities:any=[];
  
  getCommodities(){
    this.lookup.getQualityCommodity(sessionStorage.getItem("appId"),sessionStorage.getItem("appParentId"),
    sessionStorage.getItem("fiscalId"),sessionStorage.getItem("assumeEmployeeid")).subscribe(data=>{
      this.commodityForm.controls['targetOE'].setValue(data.body.qualityTarget[0].oedefecttarget);
      this.commodityForm.controls['targetService'].setValue(data.body.qualityTarget[0].servicedefecttarget);
      this.commodityForm.controls['goalPrototype'].setValue(data.body.qualityTarget[0].prototypedefecttarget);
      this.commodityForm.controls['deliveryKPM'].setValue(data.body.qualityTarget[0].deliverykpm);
      this.commodityForm.controls['warrantyTarget'].setValue(data.body.qualityTarget[0].warrantytarget);
      this.rowDataCommodities=data.body.commodity;
      for(const element of this.rowDataCommodities){
        const commodity = {}
        commodity['commodityId'] = element.appxcommodityid;
        commodity['code'] = element.commoditycode;
        commodity['description']=element.commoditydescription;
        commodity['exclude']=element.inactive;
        this.commodities.push(commodity)
      } 
    })
  }

  saveCommodity(){
    this.qualityCommodityBody={
      "appId": sessionStorage.getItem("appId"),
      "appParentId": sessionStorage.getItem("appParentId"),
      "fiscalId": sessionStorage.getItem("fiscalId"),
      "empId": sessionStorage.getItem("assumeEmployeeid"),
      "oeDefect": this.commodityForm.controls['targetOE'].value,
      "serviceDefect": this.commodityForm.controls['targetService'].value,
      "prototypeDefect": this.commodityForm.controls['goalPrototype'].value,
      "deliveryKpm": this.commodityForm.controls['deliveryKPM'].value,
      "warrantyTarget": this.commodityForm.controls['warrantyTarget'].value,
      "commodities": this.commodities
    }
    this.management.qualityCommodity(this.qualityCommodityBody).subscribe(_data=>{
      this.router.navigate(['/home']);
    })
  }

  cancelCommodity(){
    this.router.navigate(['/home']);
  }
  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }

  
}
