import { NgModule, ErrorHandler, Injector} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoggerService } from './logger.service';
import { CustomErrorHandler } from './errorhandler.provider';
import { AppRoutingModule } from './app-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import { AgGridModule } from 'ag-grid-angular';


/**Component used in the APP */
import { ImprovementTargetDescriptionComponent } from './admin/improvement-target-description/improvement-target-description.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { PacketSetComponent } from './packet-set/packet-set.component';
import { ReportsComponent } from './reports/reports.component';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { NavigationBarComponent } from './core/navigation-bar/navigation-bar.component';
import { FooterComponent } from './core/footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { IseComponent } from './ise/ise.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { IseSupplierCodeRendererComponent } from './ise/ise-supplier-code-renderer/ise-supplier-code-renderer.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { IseIetTitleRendererComponent } from './ise/ise-iet-title-renderer/ise-iet-title-renderer.component';
import { IetComponent } from './ise/iet/iet.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SupplierCodeRendererComponent } from './home/supplier-code-renderer/supplier-code-renderer.component';
import { EmailerComponent } from './admin/emailer/emailer.component';
import {
  EnvironmentConfiguration,
  environments,
  Environments,
} from './environment/services/environment.configuration';
import { ImprovementTargetEditComponent } from './admin/improvement-target-edit/improvement-target-edit.component';
import { ImprovementTargetInactiveComponent } from './admin/improvement-target-inactive/improvement-target-inactive.component';
import { ImprovementTargetOrderComponent } from './admin/improvement-target-order/improvement-target-order.component';
import { ImprovementTargetTitleComponent } from './admin/improvement-target-title/improvement-target-title.component';
import { MsalInterceptorConfig } from 'src/msal/msal-interceptor.config';
import { Configuration, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from 'src/msal';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from 'src/msal/constant';
import { MsalGuardConfiguration } from 'src/msal/msal.guard.config';
import { TokenInterceptorService } from './interceptor/token-interceptor.service';
import { JwtService } from './services/jwt.service';
import { EnvironmentModule } from './environment/environment.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuillModule } from 'ngx-quill'
import { SupplierAcknowledgementComponent } from './supplier-acknowledgement/supplier-acknowledgement.component';
import { SupplierFlagActiveComponent } from './admin/supplier-flag-active/supplier-flag-active.component';
import { SupplierFlagEditComponent } from './admin/supplier-flag-edit/supplier-flag-edit.component';
import { SupplierFlagOrderComponent } from './admin/supplier-flag-order/supplier-flag-order.component';
import { SupplierFlagTitleComponent } from './admin/supplier-flag-title/supplier-flag-title.component';
import { DropdownFloatingFilterComponent } from './core/dropdown-floating-filter/dropdown-floating-filter.component';
import { NgxSpinnerModule } from 'ngx-spinner';
let MSAL_CONFIG: Configuration = {
	auth: {
		clientId: "",
		authority: "",
		redirectUri: ""
	}
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication(MSAL_CONFIG);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}




ModuleRegistry.registerModules([ClientSideRowModelModule]);
@NgModule({
  declarations: [
    AppComponent,NavigationBarComponent,FooterComponent,AdminComponent,
    HomeComponent,PacketSetComponent,ReportsComponent,IseComponent,BreadcrumbComponent,
    IseSupplierCodeRendererComponent, SupplierDetailsComponent, IseIetTitleRendererComponent, IetComponent,
    SupplierCodeRendererComponent, EmailerComponent, ImprovementTargetDescriptionComponent, ImprovementTargetEditComponent,
    ImprovementTargetInactiveComponent, ImprovementTargetOrderComponent, ImprovementTargetTitleComponent,
    DashboardComponent,SupplierAcknowledgementComponent, SupplierFlagActiveComponent, SupplierFlagEditComponent, SupplierFlagOrderComponent, SupplierFlagTitleComponent,
    DropdownFloatingFilterComponent
  ],

  imports: [
   MatTabsModule, AgGridModule,BrowserModule,AppRoutingModule,BrowserAnimationsModule,QuillModule.forRoot(),NgxSpinnerModule,
   MatCardModule,MatNativeDateModule,MatDatepickerModule,MatToolbarModule,MatIconModule,FormsModule,ReactiveFormsModule,EnvironmentModule
  ],

  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useValue: {
      interactionType: InteractionType.Redirect
    } as MsalGuardConfiguration
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService, {
    provide: Environments,
    useValue: environments
  },
  {
    provide: Environments,
    useValue: environments,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  },  
  EnvironmentConfiguration,
  JwtService,
    LoggerService, { provide: ErrorHandler, useClass: CustomErrorHandler },
    EnvironmentConfiguration,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: Environments,
      useValue: environments,
    }, provideHttpClient()],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly injector: Injector, private readonly environment: EnvironmentConfiguration) {
		MSAL_CONFIG = {
			auth: {
				clientId: this.environment['CLIENT_ID'],
				authority: this.environment['TENANT_ID'],
				redirectUri: this.environment['REDIRECT_URL']
			}
		}
	}
 }
