import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-supplier-flag-order',
  templateUrl: './supplier-flag-order.component.html',
  styleUrl: './supplier-flag-order.component.css'
})
export class SupplierFlagOrderComponent implements ICellRendererAngularComp {
  params;

  agInit(params): void {
    this.params = params;
  }
  
  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
}
