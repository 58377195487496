import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-packetfile',
  standalone: true,
  imports: [],
  templateUrl: './packetfile.component.html',
  styleUrl: './packetfile.component.css'
})
export class PacketfileComponent implements ICellRendererAngularComp  {
  refresh(){
    return false;
  }
  params;

  agInit(params): void {
    this.params = params;
  }

  downloadpacket($event) {
    if (this.params.downloadpacket instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.downloadpacket(this.params);

    }
  }
}
