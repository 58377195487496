<div class="container-fluid">
    <div class="col-md-12 m-3">
     <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedIndex)="selectedTabIndex"
     (selectedTabChange)="onTabChanged($event)">
         <mat-tab label="Supplier Reporting Summary">
             <div class="row">
                 <div class="d-flex justify-content-between">
                     <div class="mt-2"><span class="commonFont">This tab is to export a list of Supplier Status.</span>
                     </div>
                     <div class="mt-2 ">
                         <button class="closeButton me-4" style="width: fit-content;" (click)="exportFile('grid1')">
                             <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                             EXPORT SUPPLIER LIST
                         </button>
                     </div>
                 </div>
                 <div class="">
                     <div class="d-flex">
                         <input type="checkbox" [(ngModel)]="showPrevYearsHistory" (change)="toggleColumnVisibility()"
                         style="margin-right: 10px;box-shadow: none;"> <span class="label-tmna">Show history for previous years</span>
                     </div>
                 </div>
             </div>
             <div class="row mt-2" style="margin-bottom: 50px">
                 <div class="flex-row mt-2" id="dataForSupplierReportingSummary">
                     <ag-grid-angular #grid1 class="ag-theme-balham me-4" [rowData]="rowDataforSupplierReportingSummary"  [columnDefs]="columnDefSupplierReportingSummary"
                         (gridReady)="onGridReady($event, 'grid1')" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                         [paginationPageSize]="paginationPageSize" [domLayout]="domLayout" [rowGroupPanelShow]="'always'"
                         [floatingFiltersHeight]="50" [icons]="icons" [suppressDragLeaveHidesColumns]="true">
                     </ag-grid-angular>
                 </div>
             </div>
         </mat-tab>
         <mat-tab label="Supplier IET Summary">
             <div class="row">
                 <div class="d-flex justify-content-between">
                     <div class="mt-2"><span class="commonFont">This tab is to export a list of Suppliers and APR Cost Reduction Targets.</span>
                     </div>
                     <div class="mt-2">
                         <button class="closeButton me-4" style="width: fit-content;" (click)="exportFile('grid2')">
                             <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                             EXPORT SUPPLIER LIST
                         </button>
                     </div>
                 </div>
             </div>
             <div class="row mt-2" style="margin-bottom: 50px">
                 <div class="flex-row mt-2">
                     <ag-grid-angular #grid2 class="ag-theme-balham me-4" [rowData]="rowDataforSupplierIETSummary"  [columnDefs]="columnDefSupplierIETSummary"
                         (gridReady)="onGridReady($event, 'grid2')" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                         [paginationPageSize]="paginationPageSize" [domLayout]="domLayout" [rowGroupPanelShow]="'always'"
                         [floatingFiltersHeight]="50" [icons]="icons" [tooltipShowDelay]="tooltipShowDelay" [suppressDragLeaveHidesColumns]="true">
                     </ag-grid-angular>
                 </div>
             </div>
         </mat-tab>
     </mat-tab-group>
    </div>
 </div>
 
 <div class="modal" tabindex="-1" *ngIf="showModel" [ngStyle]="{'display': showModel ? 'block':'none'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!--Need to set the heading dynamic, by having selected IET name-->
                <span class="modalHeading" style="font-size: 13px;"> {{selectedIETName}} </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeApprovedIETModel()">
                    <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" style="margin-bottom: 4px;">
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex m-1 pb-1">
                    <div class="col-md-3 ms-1">
                        <label for="heading" class="label-tmna" style="font-weight: bold;"> Heading:</label>
                    </div>
                    <div class="col-md-9 m-1 d-flex"> 
                        <input type="text" class="input-tmna disabled" [value]="supplierIETApprovedData[0]" style="width: 85%;">
                    </div>
                </div>
                <div class="d-flex m-1 pb-1">
                    <div class="col-md-3 ms-1">
                        <label for="body" class="label-tmna" style="font-weight: bold;"> Body:</label>
                    </div>
                    <div class="col-md-9 ms-1 d-flex"> 
                        <textarea class="label-tmna-big disabled" [value]="supplierIETApprovedData[1]"></textarea>
                    </div>
                </div>
                <div class="d-flex m-1 pb-1">
                    <div class="col-md-3 ms-1">
                        <label for="bullets" class="label-tmna" style="font-weight: bold;"> Bullet(s):</label>
                    </div>
                    <div class="col-md-9 m-1 d-flex">
                        <div class="bullets-listbox" style="overflow-y: scroll;">
                            <ul>
                                <li *ngFor="let bullet of supplierIETApprovedData[2]">
                                    <span class="label-tmna">{{bullet}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex m-1 pb-1">
                    <div class="col-md-3 ms-1">
                        <label for="subbullets" class="label-tmna" style="font-weight: bold;"> Sub-bullet(s):</label>
                    </div>
                    <div class="col-md-9 m-1 d-flex">
                        <div class="bullets-listbox" style="overflow-y: scroll;">
                            <ul>
                                <li *ngFor="let subbullet of supplierIETApprovedData[3]">
                                    <span class="label-tmna">{{subbullet}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>