import { Component, OnInit, HostListener } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { PacketfileComponent } from './packetfile/packetfile.component';
import { LookupService } from '../services/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-packet-set',
  templateUrl: './packet-set.component.html',
  styleUrl: './packet-set.component.css'
})
export class PacketSetComponent implements OnInit{

  ngOnInit(): void {
    this.getfy();
  }

  constructor(private readonly ngxSpinner:NgxSpinnerService,
    private readonly lookup:LookupService
    ){}

  fiscalYear:any=[];
  paginationPageSize=4;
  domLayout='autoHeight';
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: any;
  rowData=[];
  defaultColDef={ 
    suppressNavigable: true,
    suppressMovable: false,
    cellClass: 'no-border',
    enableRowGroup: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    minWidth: 140,
    suppressFloatingFilterButton: true,
   };

   icons = {
    menu: ' ',
    filter: ' '
   }
   columnDef=[
    {
      headerName: 'Strategy',
      field: 'description',
      minWidth: 40,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Supplier Category',
      field: 'temavalueimprovementtarget',
      minWidth: 40,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Supplier Flag',
      field: 'appparentflag',
      minWidth: 40,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Year',
      field: 'year',
      minWidth: 20,
      width:100,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Purchasing Group',
      field: 'purchasinggroupname',
      minWidth: 40,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Date Sent',
      field: 'datesent',
      minWidth: 20,
      width:150,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
      cellRenderer: (params) => {
        if(params.value){
        const isoformattedDate = params.value.replace(' ', 'T');
        const parsedDate = new Date(isoformattedDate);
        return new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(parsedDate);
        
        }
      }
    },
    {
      headerName: 'Supplier Confirmed',
      field: 'supplierconfirmed',
      minWidth: 20,
      width:150,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
      cellRenderer: (params) => {
        if(params.value){
          const isoformattedDate = params.value.replace(' ', 'T');
          const parsedDate = new Date(isoformattedDate);
          return new Intl.DateTimeFormat('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'numeric',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(parsedDate);
          
        }
      }
    },
    {
      headerName: 'Recipients',
      field: 'recipientemailaddress',
      minWidth: 60,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
    },
    {
      headerName: 'File',
      field: 'filename',
      minWidth: 40,
      flex: 1,
      autoHeight: true,
      enableRowGroup: true,
      cellRenderer: PacketfileComponent,
      cellRendererParams: {
        downloadpacket: this.downloadpacket.bind(this)
      }
    }
   ];

   getfy(){
    this.ngxSpinner.show();
    this.lookup.getFiscalYearListPacketSent().subscribe(data=>{
      this.fiscalYear[0]=data.body.response[0];
      this.fiscalYear[1]=data.body.response[1];
      this.fiscalYear[2]=data.body.response[2];
      this.changefy(this.fiscalYear[0].fiscalyearid);
    })
    setTimeout(()=> {
      this.ngxSpinner.hide();
    }, 2000);
   }

   downloadpacket(_params){
   }

   changefy(fiscalyearid){
    this.lookup.apphistoryget(fiscalyearid).subscribe(data=>{
      this.rowData=data.body.response;
    })
   }
   onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
}
