import { Component } from '@angular/core';
import { IFloatingFilter } from 'ag-grid-community';

@Component({
  selector: 'app-dropdown-floating-filter',
  templateUrl: './dropdown-floating-filter.component.html',
  styleUrls: ['./dropdown-floating-filter.component.css']
})
export class DropdownFloatingFilterComponent implements IFloatingFilter {
  params!: any;
  currentValue: any;
  style: any;
  optionsList: string[] = []
  currentField!: string
  selectedValue!: any

  agInit(params: any): void {
    this.params = params;
    const filterdModel = this.params.api

    this.currentField = this.params.column.colId
    if (filterdModel[this.currentField] != undefined || filterdModel[this.currentField] != null) {  //NOSONAR 
      if (filterdModel[this.currentField]?.filter.length !== 0) {
        this.selectedValue = filterdModel[this.currentField].filter
      }
      else {
        this.selectedValue = null
      }
    }
    this.optionsList = this.optionsList.concat(this.params.associatedList)
    this.style = {
      color: params.color,
    };
  }

  onParentModelChanged(parentModel: any) { //NOSONAR
    // When the filter is empty we will receive a null value here
    if (parentModel === null) { this.selectedValue = null }
    if (!parentModel) {
      this.selectedValue = "All";
    } else {
      if (this.currentField !== 'active') { this.selectedValue = parentModel.filter }
      if (this.currentField !== 'inactive') { this.selectedValue = parentModel.filter } // NOSONAR
      else {
        if (parentModel.filter === "Yes") {
          this.selectedValue = "Yes"
        }
        if (parentModel.filter === "No") {
          this.selectedValue = "No"
        }
      }

    }
  }

  onChangeFilter(event: any) { //NOSONAR
    this.selectedValue = event.target?.value;
    const filterdModel = this.params.api.getFilterModel()
    if ((this.currentField === 'active') || (this.currentField === 'inactive')) {
      let filterVal = ""
      if (this.selectedValue === "Yes") {
        filterVal = "Yes"
      }
      if (this.selectedValue === "No") {
        filterVal = "No"
      }
      filterdModel[this.currentField] = { type: 'contains', filter: filterVal, filterType: 'text' }
      this.params.api.setFilterModel(filterdModel);
    }
    else {
      filterdModel[this.currentField] = { type: 'contains', filter: this.selectedValue, filterType: 'text' }
      this.params.api.setFilterModel(filterdModel);
    }
  }
}
