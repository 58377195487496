import { Component, HostListener, OnInit } from '@angular/core';
import { BusinessEntityService } from './services/business-entity.service';
import { LookupService } from './services/lookup.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
 
  title = 'app-ui';
  isCloseClicked=false;
  newClickLink=false;
  selectedAllfunctionComp=false;
  activeLink ='active-link';
  isDisplay=false;
  isAdmin = false;
  loggedinUserName ='';
  loggedinUserRole ='';
  assumeIdentityList=[];
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  showSupplierAck = false;
  
  constructor(public bService :BusinessEntityService, private readonly router: Router,
    public lookupService: LookupService,private readonly authenticationService: AuthenticationService,){
      clearTimeout(this.userActivity);
        this.setTimeout();
        this.userInactive.subscribe(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.authenticationService.logout()
        });

      this.router.events.subscribe((event) => {
        if(event instanceof NavigationEnd) {
          this.showSupplierAck = event.url === '/supplierAcknowledgement'
        }
      })

  }
  

 
  ngOnInit(): void {
    sessionStorage.setItem("loginAssumeEmployeeid",'653');
    sessionStorage.setItem("username","Kumar Satyam");
    
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3600000) //3600000 = 60 min
  }
  @HostListener('document:keypress') refreshUserState2() {
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }
  
  @HostListener('document:click') refreshUserState3() { //NOSONAR
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }
  
  @HostListener('mousewheel') refreshUserState4() { //NOSONAR
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }


 
}


