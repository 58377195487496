import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-improvement-target-inactive',
  templateUrl: './improvement-target-inactive.component.html',
  styleUrl: './improvement-target-inactive.component.css'
})
export class ImprovementTargetInactiveComponent implements ICellRendererAngularComp  {
  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
  params;

  agInit(params): void {
    this.params = params;
  }
}
