import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SignInData } from 'src/app/model/SignInData';
import { BusinessEntityService } from '../business-entity.service';
import { LookupService } from '../lookup.service';
import { MsalService } from 'src/msal';

class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: [];
  token?: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  emailId: string;
  private readonly mockedUser = new SignInData('');
  isAuthenticated =false;
  private readonly isLoginSucceedObs = new BehaviorSubject<any>(null);
  isLoginSucceed = this.isLoginSucceedObs.asObservable();

  constructor(private readonly router:Router, private readonly bService :BusinessEntityService, private readonly lookupService: LookupService,private readonly authService: MsalService) { }

  isUserAuthenticated(){
    return false
  }
  getLoggedUserDetails(){
    return '';
  }

  authenticate(signInData: SignInData) {
    this.checkCredentials(signInData?.getEmail());
  }
 
   checkCredentials(email: string)  {
    this.emailId = email;
    this.bService.loggedUserEmail=email;
    this.isLoginSucceedObs.next('Yes');
  }

  logout(){
    this.isAuthenticated=false;
    this.isLoginSucceedObs.next('logout');
    localStorage.clear();
    sessionStorage.clear();
    this.router?.navigate(['']);
    this.authService?.logout();
  }
}
