import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable } from 'rxjs';

import { AuthenticationService } from './services/authentication/authentication.service';
import { BusinessEntityService } from './services/business-entity.service';


@Injectable()

export class GuardService implements CanActivate{
    constructor(public router:Router, 
        private readonly authenticationService: AuthenticationService,
        private readonly bService:BusinessEntityService){ }

    canActivate(route:ActivatedRouteSnapshot):boolean | Observable<boolean> | Promise<boolean>{ //NOSONAR
        
        if(this.authenticationService.isUserAuthenticated()){
            return true;
        }
    }
}