import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-supplier-flag-active',
  templateUrl: './supplier-flag-active.component.html',
  styleUrl: './supplier-flag-active.component.css'
})
export class SupplierFlagActiveComponent implements ICellRendererAngularComp {
  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
