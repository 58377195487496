import { Component } from '@angular/core';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  myimage='./assets/images/Footer-logo.svg';
  currentYear=new Date().getFullYear();
  isDisplay=false;
  constructor(public readonly lookup:LookupService){

  }
 

}
