import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ise-supplier-code-renderer',
  templateUrl: './ise-supplier-code-renderer.component.html',
  styleUrl: './ise-supplier-code-renderer.component.css'
})
export class IseSupplierCodeRendererComponent implements ICellRendererAngularComp{
  
  refresh():boolean{
    return false;
  }

  constructor(private readonly router: Router){}

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onClick(_event) {
    sessionStorage.setItem("appId",this.params.data.appid);
    sessionStorage.setItem("suppliercode",this.params.data.suppliercode);
    this.router.navigate(['/supplierDetails']);
  }
}
