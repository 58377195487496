import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-supplier-flag-edit',
  templateUrl: './supplier-flag-edit.component.html',
  styleUrl: './supplier-flag-edit.component.css'
})
export class SupplierFlagEditComponent implements ICellRendererAngularComp {
  gridApi: GridApi;
  params;
  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  agInit(params): void {
    this.params = params;
  }

  edit($event) { 
    this.params.data.editable=true;
    if (this.params.edit instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.edit(this.params);

    }
  }

  update($event) {
    this.params.data.editable=false;
    if (this.params.update instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.update(this.params);

    }
  }

  cancel($event) { 
    this.params.data.editable=false;
    if (this.params.cancel instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.cancel(this.params);

    }
  }
}
