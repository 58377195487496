import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { BusinessEntityService } from './business-entity.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  apiBaseUrl = 'http://localhost:3000';
  apiVersion = 'api/v1';
  serviceUrl = this.apiBaseUrl;
 

  contentType =  'application/json' // Sonar fix
  
  // constructor(private readonly http:HttpClient) {
  constructor(private readonly http:HttpClient,private readonly environment: EnvironmentConfiguration,private readonly bservice:BusinessEntityService) {
    this.apiBaseUrl = this.environment['apiUrl'].suppliermanagUrl;
    this.serviceUrl = this.apiBaseUrl;
  }

  public getUpdateImprvCostTrgt(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateImprvCostTrgt`;
    return this.http.put<any>(url, body, header);
  }

  public getApproveDoNotSend(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/approveDoNotSend`;
    return this.http.put<any>(url, body, header);
  }

  public qualityCommodity(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/qualityCommodity`;
    return this.http.put<any>(url, body, header);
  }
}
