import { Injectable } from '@angular/core';
import { UserAccountStoreService } from './storage/user-account-store.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  
  accessToken = null
  idToken: any = null
  userData:any = null
  constructor(
    private readonly userAccountStore: UserAccountStoreService
    ) {
    this.userAccountStore.userAccountDetails.subscribe(data => {
      this.saveTokens(data);
    })
  }

  getToken(): string {
    return this.idToken;
  }
  getUser(): string {
    return this.userData;
  }

  saveTokens(data: any) {
    if(data){
      this.accessToken = data?.accessToken
      this.idToken = data?.idToken
      this.userData = data?.account
    }
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }
}
