import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { BusinessEntityService } from './business-entity.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  apiBaseUrl = 'http://localhost:3000';
  apiVersion = 'api/v1';
  serviceUrl = this.apiBaseUrl;
  contentType =  'application/json' // Sonar fix
  
  // constructor(private readonly http:HttpClient) {
  constructor(private readonly http:HttpClient,private readonly environment: EnvironmentConfiguration, private readonly bservice : BusinessEntityService) {
    this.apiBaseUrl = this.environment['apiUrl'].lookupUrl;
    this.serviceUrl = this.apiBaseUrl;
  }
    /** observable to verify user credential */
    private readonly localStorageObservable = new BehaviorSubject<any>(null);
    localStorageData = this.localStorageObservable.asObservable();
  
    localStorageChanges(data: any) {
      this.localStorageObservable.next(data);
    }

  /** api to fetch user credentials */
  public getUserInfo(guid): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `Bearer ${this.bservice?.userToken}`,
        'params': `{ "guid":"${guid}"}`
       })
    };
    return this.http?.get<any>(this.environment['apiUrl'].lookupUrl + `api/v1/userInfo?guid=${guid}`, httpCustomOptions);
  }

  public getSupplierList(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/supplierList`;
    return this.http.post<any>(url, body, header);
  }

  public getSftyRiskCommodity(appParentId, fiscalId, empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSftyRiskCommodity?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`;
    return this.http.get<any>(url, header);
  }

  public getSupplierTierResult(appParentId, fiscalId, empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierTierResult?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`
    return this.http.get<any>(url, header);
  }

  public getISESuppliers(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getISEList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public toggleISESuppliers(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/toggleIseSupplier`;
    return this.http.put<any>(url, body, header);
  }

  public getAssumeList(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getAssumeIdentityList?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getAssumeInfo(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getAssumeIdentityInfo?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getStandardIETList(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public getStandardIETBulletDesc(standardiet): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETBulletDesc?standardiet=${standardiet}`;
    return this.http.get<any>(url, header);
  }

  public addIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addIET`;
    return this.http.post<any>(url, body, header);
  }

  public updateIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateIET`;
    return this.http.put<any>(url, body, header);
  }

  public deleteIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/deleteIET`;
    return this.http.post<any>(url, body, header);
  }

  public addIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addIETBullet`;
    return this.http.post<any>(url, body, header);
  }

  public updateIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateIETBullet`;
    return this.http.put<any>(url, body, header);
  }

  public deleteIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/deleteIETBullet`;
    return this.http.post<any>(url, body, header);
  }

  public getQualityCommodity(appId,appParentId,fiscalId,empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getQualityCommodity?appId=${appId}&appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`;
    return this.http.get<any>(url, header);
  }

  public getGeneralIETList(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getGeneralIETList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public getGeneralIETBulletDesc(generaliet): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getGeneralIETBulletDesc?generaliet=${generaliet}`;
    return this.http.get<any>(url, header);
  }

  public getManagerNames(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getManagerNames`;
    return this.http.get<any>(url, header);
  }
  
  public getImprovementTarget(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getImprovementTarget`;
    return this.http.get<any>(url, header);
  }

  public addNewImprovementTarget(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addNewImprovementTarget`;
    return this.http.post<any>(url, body, header);
  }

  public updateImprovementTarget(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateImprovementTarget`;
    return this.http.put<any>(url, body, header);
  }

  public getStandardIETNamesInCurrentFY(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETNamesInCurrentFY`;
    return this.http.get<any>(url, header);
  }

  public getSuppliersByIETName(ietname): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSuppliersByIETName?ietname=${ietname}`;
    return this.http.get<any>(url, header);
  }
  
  public applyStandardIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/applyStandardIET`;
    return this.http.post<any>(url, body, header);
  }

  public getFiscalYearListPacketSent(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getFiscalYearListPacketSent`;
    return this.http.get<any>(url, header);
  }

  public apphistoryget(fiscalyearid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/apphistoryget?fiscalyearid=${fiscalyearid}`;
    return this.http.get<any>(url, header);
  }

  public getApprovedAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getApprovedAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getNotSentAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getNotSentAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getOpenAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getOpenAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getSupplierFlags(fiscalId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierFlags?fiscalId=${fiscalId}`;
    return this.http.get<any>(url, header);
  }

  public appparentflagtemplateinsert(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appparentflagtemplateinsert`;
    return this.http.post<any>(url, body, header);
  }

  public appparentflagtemplateupdate(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appparentflagtemplateupdate`;
    return this.http.post<any>(url, body, header);
  }

  public getIndividualExpectedTask(appparetid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getIndividualExpectedTask?appparetid=${appparetid}`;
    return this.http.get<any>(url, header);
  }

  public getISEDetails(appparentid, employeeid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getISEDetails?appparentid=${appparentid}&employeeid=${employeeid}`
    return this.http.get<any>(url, header);
  }
}