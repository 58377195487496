import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.css'
})
export class NavigationBarComponent implements OnInit{
  
  title = 'app-ui';
  isCloseClicked=false;
  reportIssueUrl = "https://tmna.service-now.com/1ts";
  newClickLink=false;
  selectedAllfunctionComp=false;
  activeLink ='active-link';
  loginUserName;
  loginUserRole;
  validation;
  isDisplay=false;
  adminAcess=false;
  
  constructor(private readonly router: Router,private readonly lookup:LookupService,public bService :BusinessEntityService,public authentService:AuthenticationService
    ) {}
  employeeId:any=0;
  ngOnInit(): void {
    /**Checking the sucessful user after login and setting then up */
    this.validation=this.lookup.localStorageData.subscribe(data=>{
      if(data?.includes('success')){
        
        this.isDisplay=true;
        this.loginUserName = this.bService.loggedUserName;
        if(this.bService.loggedUserRole==='PurappsAPPAdmin'){
          this.loginUserRole='Admin';this.adminAcess=true
        }else if(this.bService.loggedUserRole==='PurappsBuyer'){
          this.loginUserRole='Buyer';
        }else if(this.bService.loggedUserRole==='PurappsManager'){
          this.loginUserRole='Manager';
          if(this.bService?.themeOwner){
            this.adminAcess=true
          }

        }
       
        

      }
    })

    this.getAssumeList();
    if(sessionStorage.getItem("assumeEmployee") && sessionStorage.getItem("assumeEmployee")!=="undefined"){
      this.assumeSelect=sessionStorage.getItem("assumeEmployee");
      this.assumed=true;
    }
  }

  getAssumeList(){
    this.lookup.getAssumeList(this.employeeId).subscribe(data=>{
      this.assumeIdentity=data.body.response;
      this.mainAssumeIdentity=data.body.response;
    })
  }
  assumeIdentity:any;
  mainAssumeIdentity:any;
  inputAssume:any;
  assumeSelect:any;
  assumeEmployeeId:any;
  assumed:any=false;
  search(inputAssume:any){
    this.assumeSelect='';
    if(inputAssume.length===0){
      this.assumeIdentity=this.mainAssumeIdentity;
    }
    else {
    const dummyAssumeIdentity=[];
    for(let i=0;i<this.mainAssumeIdentity.length;i++){
      if((this.mainAssumeIdentity[i].pdkusrname.toLowerCase()).includes(inputAssume.toLowerCase())){
        dummyAssumeIdentity.push(this.mainAssumeIdentity[i])
      }
    }
    this.assumeIdentity=dummyAssumeIdentity;
    }
  }

  selectAssume(assume:any,employeeid:any){
    this.assumeSelect=assume;
    this.assumeEmployeeId=employeeid;
    sessionStorage.setItem("assumeEmployeeid",employeeid);
    sessionStorage.setItem("assumeEmployee",assume);
  }

  assume(){
    this.lookup.getAssumeInfo(this.assumeEmployeeId).subscribe(data=>{
      this.assumed=true;
      this.assumeSelect='';
      sessionStorage.setItem("assumeUsername",data.body.response.username);
      sessionStorage.setItem("assumeIsmanagement",data.body.response.ismanagement);
      sessionStorage.setItem("assumePermissions",data.body.response.permissions);
      sessionStorage.setItem("assumePurchasinggroupid",data.body.response.purchasinggroupid);
      if(sessionStorage.getItem("page")==="home"){ this.ngOnInit();
      this.redirect('home'); }
    })
  }

  cancelassume(){
    this.assumed=false;
    sessionStorage.setItem("assumeEmployeeid",sessionStorage.getItem("loginAssumeEmployeeid"));
    this.assumeSelect='';
    sessionStorage.removeItem("assumeUsername");
    sessionStorage.removeItem("assumeIsmanagement");
    sessionStorage.removeItem("assumePermissions");
    sessionStorage.removeItem("assumePurchasinggroupid");
    if(sessionStorage.getItem("page")==="home"){
      this.redirect('home'); }
  }

  redirect(uri:string){
    this.router.navigateByUrl('/admin', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

 reportIssue(){
  window.open(this.reportIssueUrl, '_blank')
 }

 logout(){
  localStorage.clear();
  window.history.pushState({},document.title,'');
  sessionStorage.clear();
  this.authentService.logout();
}
 ngOnDestroy(){
  this.validation.unsubscribe();
 }
}
