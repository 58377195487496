import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-supplier-acknowledgement',
  templateUrl: './supplier-acknowledgement.component.html',
  styleUrl: './supplier-acknowledgement.component.css'
})
export class SupplierAcknowledgementComponent {

  supplierAckForm: FormGroup;
  responseId: string;
  alreadyAcknowledged = false;
  fiscalYear: number;
  responseKeyValue: string;
  noResponseKeyInUrl = false;

  constructor(private readonly fb: FormBuilder, private readonly ngxSpinner:NgxSpinnerService) {
    this.supplierAckForm = this.fb.group({
      greetings: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: ['', [Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      checkboxAcknowledge: [false, Validators.requiredTrue]
    })
  }

  salutations = ['Mr.', 'Mrs.', 'Ms.'];
  groupEmailId = 'SPMVolume@toyota.com';
  submitRequestBody: {
    greeting: string,
    firstname: string,
    lastname: string,
    contacttitle: string,
    email: string,
    responseId: string
  }
  supplierAckData: any;

  ngOnInit() {
    this.ngxSpinner.show();
    this.getSupplierAcknowledgementData();
    this.disableSupplierAckForm();
    setTimeout(()=>{
      this.ngxSpinner.hide();
    },2000)
  }

  getSupplierAcknowledgementData() {
    //get response key value from url
    const key = this.getResponseKeyParameter('RKey');
    this.responseKeyValue = key;

    //getApi with the responseKeyValue as payload to get below response
    if(this.responseKeyValue && this.responseKeyValue.length === 36 && this.responseKeyValue === "f6a9280b-2b47-4237-852c-ae4bb4f05b74") { //for now setting the responseKeyValue with length to check in dev
      this.supplierAckData = {
        "greeting": "Mr.",
        "firstname": "Aravindan",
        "lastname": "Karthikeyan",
        "contacttitle": "Test",
        "email": "test@email.com",
        "response": true, //based on the flag we find whether already acknowledged or not
        "suppliername": "Akebono Brake",
        "fiscalyearid": "40",
        "duedate": "01/31/2025",
        "dateresponded": "01/26/2024",
        "year": "2025",
        "acknowledgementtext": "I acknowledge I have received And read the FY26 TMNA Annual Purchasing Policy. I will ensure this policy is shared with the appropriate individuals in my company."
      }
    } else if (this.responseKeyValue && this.responseKeyValue.length === 36){
      this.supplierAckData = {
        "greeting": null,
        "firstname": null,
        "lastname": null,
        "contacttitle": null,
        "email": null,
        "response": false, //based on the flag we find whether already acknowledged or not
        "suppliername": "Akebono Brake",
        "fiscalyearid": "40",
        "duedate": "01/31/2025",
        "dateresponded": "01/26/2024",
        "year": "2025",
        "acknowledgementtext": "I acknowledge I have received And read the FY26 TMNA Annual Purchasing Policy. I will ensure this policy is shared with the appropriate individuals in my company."
      }
    } else {
      this.noResponseKeyInUrl = true;
    }
    
    this.fiscalYear = (this.supplierAckData?.year % 100) + 1;
    if(this.supplierAckData?.response === true){
      this.alreadyAcknowledged = true;
      this.supplierAckForm = this.fb.group({
        greetings: this.supplierAckData.greeting,
        firstName: this.supplierAckData.firstname,
        lastName: this.supplierAckData.lastname,
        title: this.supplierAckData.contacttitle,
        email: this.supplierAckData.email,
        checkboxAcknowledge: this.alreadyAcknowledged
      })
    } else {
      this.alreadyAcknowledged = false;
    }
  }

  getResponseKeyParameter(param: string): string | null {
    const urlParam = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParam.entries() ){
      if(key.toLowerCase() === param.toLowerCase()) {
        return value;
      }
    }
    return null;
  }

  onSubmit() {
    //If not acknowledged, need to trigger api to pass the entered values
    this.submitRequestBody = {
      greeting: this.supplierAckForm.value.greetings,
      firstname: this.supplierAckForm.value.firstName,
      lastname: this.supplierAckForm.value.lastName,
      contacttitle: this.supplierAckForm.value.title,
      email: this.supplierAckForm.value.email,
      responseId: this.responseId
    }
    if(this.supplierAckForm.valid) {
      const formData = this.submitRequestBody;
      console.log('Submitted Form Data: ', formData)
    } else {
      this.supplierAckForm.markAllAsTouched();
    }
  }

  disableSupplierAckForm() {
    if(this.alreadyAcknowledged === true) {
      this.supplierAckForm.controls['greetings'].disable();
      this.supplierAckForm.controls['firstName'].disable();
      this.supplierAckForm.controls['lastName'].disable();
      this.supplierAckForm.controls['email'].disable();
      this.supplierAckForm.controls['title'].disable();
      this.supplierAckForm.controls['checkboxAcknowledge'].disable();
    }
  }

  get form() {
    return this.supplierAckForm.controls;
  }

}
