import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { Observable } from 'rxjs';
import { BusinessEntityService } from './business-entity.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  apiBaseUrl = 'http://localhost:3000';
  apiVersion = 'api/v1';
  serviceUrl = this.apiBaseUrl;
 

  contentType =  'application/json' // Sonar fix
  
  // constructor(private readonly http:HttpClient) {
  constructor(private readonly http:HttpClient,private readonly environment: EnvironmentConfiguration,private readonly bservice:BusinessEntityService) {
    this.apiBaseUrl = this.environment['apiUrl'].adminUrl;
    this.serviceUrl = this.apiBaseUrl;
  }

  public getapplyPermission(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/applyPermission`;
    return this.http.post<any>(url, body, header);
  }

  public changeFiscalYear(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/changeFiscalYear`;
    return this.http.post<any>(url, body, header);
  }

  public updateFiscalYear(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateFiscalYear`;
    return this.http.post<any>(url, body, header);
  }

  //fiscalYearStatus
  public fiscalYearStatus(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/fiscalYearStatus`;
    return this.http.get<any>(url, header);
  }

  public getSupplierEmails(fiscalId,filter): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierEmails?fiscalId=${fiscalId}&filter=${filter}`;
    return this.http.get<any>(url, header);
  }

  public getSupplierEmailerInfo(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierEmailerInfo`;
    return this.http.get<any>(url, header);
  }

  public updateEmailerFiscalYear(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateEmailerFiscalYear`;
    return this.http.post<any>(url, body, header);
  }

  public saveEmailerContent(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/saveEmailerContent`;
    return this.http.post<any>(url, body, header);
  }

  public sendEmail(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/sendEmail`;
    return this.http.post<any>(url, body, header);
  }

}
