// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    envName: 'local',
    production: false,
    apiBaseUrl: 'https://dev.app.toyota.com',
    logger: {
      level: 'DEBUG'
    },
    TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
    CLIENT_ID: '21bbd591-6a5e-435f-acba-0e8be4ebfdbb',
    REDIRECT_URL: 'http://localhost:4200',
    apiUrl: {
        lookupUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/lookup/',
        adminUrl:  'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/admin/',
        suppliermanagUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/management/'
    }
  };
  