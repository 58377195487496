export const checkMimeTypn = (files:any=null) =>{
    
    if (files && files.length > 0) {
        const file = files[0];
         

        const fileReader = new FileReader();
        fileReader.onloadend = function (e:any) {
            // return checkHeader(e)
            const arr = (new Uint8Array(e?.target?.result)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
             
        
            // Check the file signature against known types
            let type = 'unknown';
            switch (header) {
                case '89504e47':
                    type = 'image/png';
                    break;
                case '47494638':
                    type = 'image/gif';
                    break;
                case 'ffd8ffe0':
                case 'ffd8ffe1':
                case 'ffd8ffe2':
                    type = 'image/jpeg';
                    break;
                case '25504446':
                    type = 'application/pdf';
                    break;
            }
           
        
            
            return type;
        };
        fileReader.readAsArrayBuffer(file);
    }
}
export const checkMimeTypnURL = (MY_URL:string) =>{
    const request = new XMLHttpRequest();
    request.open('GET', MY_URL, true);
    request.responseType = 'blob';
    request.onload = function() {
        const reader = new FileReader();
        reader.readAsArrayBuffer(request.response);
        reader.onload =  function(e){
             
            return checkHeader(e)
        };
    };
    request.send();
}
export const checkMimeTypcv = (files:any=null, callback: { (e: any): void; (arg0: string): void; }) =>{ // NOSONAR
     
    if (files && files.length > 0) {
        const file = files[0];
         
 
        const fileReader = new FileReader();
        fileReader.onloadend = function (e:any) {
            callback(checkHeader(e))
            //return callback(a)
        };
        fileReader.readAsArrayBuffer(file);
    }
}
const checkHeader = (e:any) =>{
    const arr = (new Uint8Array(e?.target?.result)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
    }
     

    // Check the file signature against known types
    let type = 'unknown';
    switch (header) {
        case '89504e47':
            type = 'image/png';
            break;
        case '47494638':
            type = 'image/gif';
            break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
            type = 'image/jpeg';
            break;
        case '25504446':
            type = 'application/pdf';
            break;
    }
     

     
    return type;
}
