
export const environment = {
    envName: 'dev',
    production: false,
    hostname: 'dev.app.toyota.com',
    apiBaseUrl: 'https://dev.app.toyota.com',
    logger: {
      level: 'DEBUG'
    },
    xApiKey: '',
    TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
    CLIENT_ID: '21bbd591-6a5e-435f-acba-0e8be4ebfdbb',
    REDIRECT_URL: 'https://dev.app.toyota.com',
    apiUrl: {
        lookupUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/lookup/',
        adminUrl:  'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/admin/',
        suppliermanagUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/management/'
    }
  };
  