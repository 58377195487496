
export const environment = {
    envName: 'prod',
    production: true,
    hostname: 'app.toyota.com',
    apiBaseUrl: 'https://app.toyota.com',
    logger: {
      level: 'INFO'
    },
    xApiKey: '',
    TENANT_ID: 'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb',
    CLIENT_ID: '',
    REDIRECT_URL: 'https://app.toyota.com',
    apiUrl: {
        lookupUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/lookup/',
        adminUrl:  'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/admin/',
        suppliermanagUrl: 'https://dlrqja4fwj.execute-api.us-west-2.amazonaws.com/dev/management/'
    }
  };
  