import { Component } from '@angular/core';
import { BusinessEntityService } from '../services/business-entity.service';
import { LookupService } from '../services/lookup.service';

import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  suppliers = []
  supplierList: any;
  selectedSuppList: any;
  errorMessage;
  displayUnauthorized=false;
  checkValidity;
  constructor( public bService :BusinessEntityService,private readonly lookup: LookupService, private readonly router: Router, private readonly ngxSpinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.checkValidity = this.lookup.localStorageData.subscribe(data => { //checking whether authorized or not


      if (data?.includes('success')) {

        this.router.navigate(['./home']);
        const localStorageAssumeUser = JSON.parse(localStorage.getItem('userAssumed'));  //checking whether user is assumed or not
        if (localStorageAssumeUser) {
          this.bService.loggedUserRoleRank = localStorageAssumeUser.rolerank;
          this.bService.loggedUserBuyerCode = localStorageAssumeUser.buyercode;
          this.bService.loggedUserManagerCode = localStorageAssumeUser.managercode
        }
        if (this.bService.loggedUserRoleRank === 5) { //Admin has access to only one screen so redirect
          //this.router.navigate(['./admin']);
        } else {  //if authorized and has buyer or manager role 
          this.displayUnauthorized = false;
          this.displaydashboardDetails();
        }
      } else if (data?.includes('failure')) { // if unauthorized
        this.displayUnauthorized = true;
      }
    })
    this.checkValidity.unsubscribe();
    setTimeout(()=> {
      this.ngxSpinner.hide();
    },2000);
  }
  displaydashboardDetails() {
    let params = {}
    if (this.bService.loggedUserRoleRank===4) {
      params = {
        managerCode:this.bService.loggedUserManagerCode
      };
    } else if (this.bService.loggedUserRoleRank===3) {
      params = {
        buyerCode:this.bService.loggedUserBuyerCode
      };
    }
    
    this.errorMessage=true;
    
  }

  ngOnDestroy(){ //destroying the observables in very important.
    this.checkValidity.unsubscribe();
  }

}
