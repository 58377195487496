import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface UserAccountModel{
  environment?: string,
  homeAccountId?: string,
  idTokenClaims?: {
    roles?: any
  },
  localAccountId?: string,
  name?: string,
  tenantId?: string,
  username?: string
}
@Injectable({
  providedIn: 'root'
})
export class UserAccountStoreService {
  account: any = {
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    roles: [],
    localAccountId: '',
    name: '',
    tenantId: '',
    username: ''
  };

  constructor() { }
  private readonly userAccountBehavior = new BehaviorSubject<any>(null);
  userAccountDetails = this.userAccountBehavior.asObservable();
  isuserdetails = false;

  setAccountDetails(data: any){
    if(this.isuserdetails === false){
    this.userAccountBehavior.next(data);
    this.isuserdetails=true;
    }
  }

  getAccountDetails(userAccountDetails: any){
    if(userAccountDetails !== null){
      const accountDetails = userAccountDetails as UserAccountModel;
      const roles = accountDetails?.idTokenClaims?.roles
      const accountDetailsObj = {
        environment: accountDetails?.environment,
        homeAccountId: accountDetails?.homeAccountId,
        idTokenClaims: accountDetails?.idTokenClaims,
        roles: roles,
        localAccountId: accountDetails?.environment,
        name: accountDetails?.name,
        tenantId: accountDetails?.tenantId,
        username: accountDetails?.username
      };
      this.account = accountDetailsObj;
    }
    return this.account
  }
}
