import { Component, HostListener, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GridApi, ITooltipParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})
export class ReportsComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selectedTabIndex = 0;
  paginationPageSize = 10;
  domLayout = 'autoHeight';
  currentYear = new Date().getFullYear();
  tooltipShowDelay = 100;
  gridApi: GridApi;
  gridColumnApi: any;
  showPrevYearsHistory = false;
  @ViewChild('grid1') grid1: any;
  @ViewChild('grid2') grid2: any;
  gridApiMap = new Map<string, any>();
  showModel = false;
  selectedIETName: any;

  onTabChanged(_event: MatTabChangeEvent) {
    setTimeout(() => {
      if (this.gridApiMap.has('grid1')) {
        this.gridApiMap.get('grid1').sizeColumnsToFit();
      } else if (this.gridApiMap.has('grid2')) {
        this.gridApiMap.get('grid2').sizeColumnsToFit();
      }
    })
  }

  constructor(private readonly ngxSpinner:NgxSpinnerService) { }

  defaultColDef = {
    suppressNavigable: true,
    suppressMovable: false, //Suppressing column drag drop.
    cellClass: 'no-border',
    enableRowGroup: true, //To enable ag-grid enterprise Drag, Drop and grouping functionality and in .html file [rowGroupPanelShow]="'always'"
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    minWidth: 140,
    suppressFloatingFilterButton: true,
    flex: 1
  };

  icons = {
    menu: ' ',
    filter: ' '
  }

  columnDefSupplierReportingSummary = [
    {
      headerName: 'SUPPLIER',
      field: 'supplier',
      minWidth: 240,
      flex: 3
    },
    {
      headerName: 'LEAD BUYER CODE',
      field: 'lead_buyer_code',
      flex: 1
    },
    {
      headerName: 'LEAD BUYER',
      field: 'lead_buyer',
      flex: 1
    },
    {
      headerName: 'ASSISTANT MANAGER',
      field: 'assistant_manager',
      flex: 2
    },
    {
      headerName: 'MANAGER',
      field: 'manager',
      flex: 1
    },
    {
      headerName: 'APPROVAL STATUS',
      field: 'status',
      flex: 1
    },
    {
      headerName: `CURRENT APR COST REDUCTION TARGET (${this.currentYear})`,
      field: 'current_apr_cost_reduction_target',
      flex: 1,
      minWidth: 220
    },
    {
      headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 1})`,
      field: 'previous_apr_cost_reduction_target',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 2})`,
      field: 'previous_apr_cost_reduction_target2',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 3})`,
      field: 'previous_apr_cost_reduction_target3',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 4})`,
      field: 'previous_apr_cost_reduction_target4',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `MBE CURRENT YEAR (${this.currentYear})`,
      field: 'mbe_current',
      flex: 1,
      minWidth: 220
    },
    {
      headerName: `WBE CURRENT YEAR (${this.currentYear})`,
      field: 'wbe_current',
      flex: 1,
      minWidth: 220
    },
    {
      headerName: `MBE PREVIOUS YEAR (${this.currentYear - 1})`,
      field: 'mbe_previous',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `WBE PREVIOUS YEAR (${this.currentYear - 1})`,
      field: 'wbe_previous',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `MBE PREVIOUS YEAR (${this.currentYear - 2})`,
      field: 'mbe_previous2',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `WBE PREVIOUS YEAR (${this.currentYear - 2})`,
      field: 'wbe_previous2',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `MBE PREVIOUS YEAR (${this.currentYear - 3})`,
      field: 'mbe_previous3',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `WBE PREVIOUS YEAR (${this.currentYear - 3})`,
      field: 'wbe_previous3',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `MBE PREVIOUS YEAR (${this.currentYear - 4})`,
      field: 'mbe_previous4',
      flex: 1,
      minWidth: 220,
      hide: true
    },
    {
      headerName: `WBE PREVIOUS YEAR (${this.currentYear - 4})`,
      field: 'wbe_previous4',
      flex: 1,
      minWidth: 220,
      hide: true
    }
  ]

  rowDataforSupplierReportingSummary = [
    { 'supplier': '3M', 'lead_buyer_code': '05002', 'lead_buyer': 'De Armas, Jose', 'assistant_manager': 'Tolley, Alexus', 'manager': 'Fozkos, Mark', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'A Raymond Tinnerman AutomotiveInc.', 'lead_buyer_code': '05040', 'lead_buyer': 'Iyer, Katlyn', 'assistant_manager': 'sly, robert', 'manager': 'Philips, Randy', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Aisan', 'lead_buyer_code': '05044', 'lead_buyer': 'Sherman, Ryan', 'assistant_manager': 'Krystyniak, Corey', 'manager': 'Philips, Randy', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Valeo', 'lead_buyer_code': '05038', 'lead_buyer': 'Fromm, Nalani', 'assistant_manager': 'McManama, David', 'manager': '11008, TBD', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Star Manufacturing, Inc.', 'lead_buyer_code': '05092', 'lead_buyer': 'Siebarth, Kevin', 'assistant_manager': 'Collom, Lisa', 'manager': 'Palmer, Samuel', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'ZF Active Safety and Electronics US LLC', 'lead_buyer_code': '05032', 'lead_buyer': 'Niazi, Zuhair', 'assistant_manager': 'McManama, David', 'manager': '11008, TBD', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2'}, 
    { 'supplier': 'CAPCO LLC', 'lead_buyer_code': '05027', 'lead_buyer': 'Smith, Mark', 'assistant_manager': 'sly, robert', 'manager': 'Philips, Randy', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Ahresty Wilmington Corporation', 'lead_buyer_code': '05085', 'lead_buyer': 'Collins, Jack', 'assistant_manager': 'Gartland, Stacey', 'manager': 'Philips, Randy', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'DC Safety Sales Co., Inc.', 'lead_buyer_code': '05222', 'lead_buyer': 'Risko, Alon', 'assistant_manager': '', 'manager': '', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Flex-N-Gate', 'lead_buyer_code': '05087', 'lead_buyer': 'Zhuo, Zack', 'assistant_manager': 'Rapach, Michael', 'manager': 'Hausmann, Pete', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Truck-Lite', 'lead_buyer_code': '05213', 'lead_buyer': 'Cotton, Bailey', 'assistant_manager': 'Barnes, Bryant', 'manager': '', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' },
    { 'supplier': 'Yokohama Tire Corporation', 'lead_buyer_code': '05019', 'lead_buyer': 'Katopol, Renee', 'assistant_manager': '', 'manager': 'Marshall III, LeRoy', 'status': 'Open', 'current_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target': '2.00%', 'previous_apr_cost_reduction_target2': '2.00%', 'previous_apr_cost_reduction_target3': '2.00%', 'previous_apr_cost_reduction_target4': '2.00%', 'mbe_current': '5', 'wbe_current': '2', 'mbe_previous': '4', 'wbe_previous': '2', 'mbe_previous2': '4', 'wbe_previous2': '2', 'mbe_previous3': '4', 'wbe_previous3': '2', 'mbe_previous4': '4', 'wbe_previous4': '2' }
  ]
  
  supplierIETApprovedData = [ //Model data
    'IET Heading',
    'IET Body',
    ['IET Bullet1', 'IET Bullet2', 'IET Bullet3', 'IET Bullet4'],
    ['IET Subbullet1','IET Subbullet2','IET Subbullet3','IET Subbullet4'],
    'Open',
    'Yes'
  ]

  responseofColSupplierIETSummary = {
    columns: [
      {"headerName": "Supplier", "field": "supplier"},
      {"headerName": "Delivery", "field": "delivery"},
      {"headerName": "Export", "field": "export"},
      {"headerName": "Human Resource", "field": "hrDept"},
      {"headerName": "Logistics", "field": "logistics"},
      {"headerName": "Gentani", "field": "gentani"},
      {"headerName": "Cost", "field": "cost"},
      {"headerName": "Localization", "field": "localization"},
      {"headerName": "Mexico", "field": "mexico"},
      {"headerName": "Monazukuri", "field": "monuzukuri"},
      {"headerName": "Design or Technology", "field": "designTech"},
      {"headerName": "Packaging", "field": "package"},
      {"headerName": "Capacity", "field": "capacity"},
      {"headerName": "Production Preparation", "field": "productionPrep"},
      {"headerName": "Production Management", "field": "productionMang"},
      {"headerName": "Quality", "field": "quality"},
      {"headerName": "Service", "field": "service"},
	    {"headerName": "Tooling", "field": "tooling"},
      {"headerName": "Total", "field": "total"},
      {"headerName": "Comments", "field": "comments"}
    ]
  };

  columnDefSupplierIETSummary: any[] = [];

  ngOnInit(){
    this.ngxSpinner.show();
    this.columnDefSupplierIETSummary = this.createSupplierIETSummaryColDef(this.responseofColSupplierIETSummary.columns);
    setTimeout(()=> {
      this.ngxSpinner.hide();
    }, 2000);
  }

  createSupplierIETSummaryColDef(columns: any[]): any[] { //Dynamic Supplier IET Summary column rendering
    return columns.map((col)=>{
      if(col.field === "comments") {
        return {
          ...col,
          editable: true,
          tooltipValueGetter: toolTipValueGetter
        }
      } else if(col.field === "supplier") {
        return {
          ...col,
          minWidth: 240
        }
      } else {
        return {
          ...col,
          cellRenderer: (params: any) => {
            if(params.value === 'X' || params.value === 'x'){
              const link = document.createElement('a');
              link.href = 'javascript:void(0)';
              link.innerText = params.value;
              link.addEventListener('click', () =>
              this.openModal(col.headerName));
              return link;
            }
            return params.value;
          },
        };
      }
    });
  }

  rowDataforSupplierIETSummary = [
    { 'supplier': '3M', 'delivery': '', 'export': '', 'hrDept': 'X', 'logistics': '' ,'gentani': '', 'cost': '', 'localization': 'X', 'mexico': '', 'monuzukuri': '', 'designTech': 'X', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': 'X', 'tooling': '', 'total': '4', 'comments': 'Status is open.' },
    { 'supplier': 'A Raymond Tinnerman AutomotiveInc.', 'delivery': '', 'export': 'X', 'hrDept': '', 'logistics': 'X', 'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': 'X', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '3', 'comments': '' },
    { 'supplier': 'Aisan', 'delivery': 'X', 'export': '', 'hrDept': 'X', 'logistics': '' ,'gentani': '', 'cost': '', 'localization': '', 'mexico': 'X', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': 'X', 'service': '', 'tooling': '', 'total': '4', 'comments': 'Status is open.' },
    { 'supplier': 'Valeo', 'delivery': '', 'export': 'X', 'hrDept': '', 'logistics': '', 'gentani': 'X', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '2', 'comments': '' },
    { 'supplier': 'Star Manufacturing, Inc.', 'delivery': '', 'export': '', 'hrDept': 'X', 'logistics': '' ,'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': 'X', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '2', 'comments': 'Status is open.' },
    { 'supplier': 'ZF Active Safety and Electronics US LLC', 'delivery': '', 'export': 'X', 'hrDept': '', 'logistics': '', 'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '1', 'total': '1', 'comments': '' },
    { 'supplier': 'CAPCO LLC', 'delivery': '', 'export': '', 'hrDept': 'X', 'logistics': '' ,'gentani': 'X', 'cost': 'X', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '3', 'comments': 'Status is open.' },
    { 'supplier': 'Ahresty Wilmington Corporation', 'delivery': '', 'export': 'X', 'hrDept': '', 'logistics': '', 'gentani': '', 'cost': 'X', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '2', 'comments': '' },
    { 'supplier': 'DC Safety Sales Co., Inc.', 'delivery': '', 'export': '', 'hrDept': '', 'logistics': '' ,'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '0', 'comments': 'Status is open.' },
    { 'supplier': 'Flex-N-Gate', 'delivery': '', 'export': 'X', 'hrDept': '', 'logistics': '', 'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '1', 'comments': '' },
    { 'supplier': 'Truck-Lite', 'delivery': '', 'export': '', 'hrDept': 'X', 'logistics': '' ,'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '1', 'comments': 'Status is open.' },
    { 'supplier': 'Yokohama Tire Corporation', 'delivery': 'X', 'export': '', 'hrDept': '', 'logistics': '', 'gentani': '', 'cost': '', 'localization': '', 'mexico': '', 'monuzukuri': '', 'designTech': '', 'package': '', 'capacity': '', 'productionPrep': '', 'productionMang': '', 'quality': '', 'service': '', 'tooling': '', 'total': '1' , 'comments': 'Status is open. Testing with more letters to view long sentence in the comments cell' }
  ]

  openModal(headerName) {
    this.selectedIETName = headerName;
    this.showModel = true;
    return this.selectedIETName;
  }
  
  closeApprovedIETModel(): void {
    this.showModel = false;
  }
  
  onGridReady(params: any, gridId: string): void {
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApiMap.set(gridId, params.api);
    params.api?.sizeColumnsToFit();
  }

  @HostListener("window:resize", ['$event'])
  onResize() {
    if (this.gridApi != null) {
      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }
  }

  toggleColumnVisibility(): void {
    if (this.gridApiMap.has('grid1')) {
      const gridApi = this.gridApiMap.get('grid1');
      const previousYearHistoryColumns = [
        'previous_apr_cost_reduction_target',
        'previous_apr_cost_reduction_target2',
        'previous_apr_cost_reduction_target3',
        'previous_apr_cost_reduction_target4',
        'mbe_previous',
        'wbe_previous',
        'mbe_previous2',
        'wbe_previous2',
        'mbe_previous3',
        'wbe_previous3',
        'mbe_previous4',
        'wbe_previous4'
      ]
      gridApi.setColumnsVisible(previousYearHistoryColumns, this.showPrevYearsHistory);
    }
  }

  exportFile(gridId: string) {
    if (this.gridApiMap.has(gridId)) {
      const fileName = gridId === 'grid1' ? 'APP Supplier Reporting Summary' : 'APP IET Matrix';
      this.gridApiMap.get(gridId).exportDataAsExcel({
        fileName: fileName
      });
    }
  }

}
const toolTipValueGetter = (params: ITooltipParams) =>
  params.value == null || params.value === "" ? "" : params.value;
