import { Component, HostListener } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-iet',
  templateUrl: './iet.component.html',
  styleUrl: './iet.component.css'
})
export class IetComponent {
  isOpen1=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  breadcrumbItems=[
    {
      label: 'Home',
      link: '/home',
      icon: '>'
    },
    {
      label: 'View/edit ISE',
      link: '/ise',
      icon: '>'
    },
    {
      label: 'Individual Expected Tasks',
      link: null,
      icon: ''
    },
  ];
  bullets: string[] = ['Ensure timely submission and accuracy of Toyota safety surveys.'];
  subBullets: string[] = [];
  selectedIndex: number | null = 0;
  selectedSubBulletIndex: number | null = null;
  showBulletModal = false;
  showSubBulletModal = false;
  bulletText = '';
  subBulletText = '';
  subBulletsMap: { [key: number]: string[] } = {};
  isEditMode = false;
  isEditSubBulletMode = false;
  isBulletReorder = false;
  isSubBulletReorder = false;
  isdeleteBullet = false;
  isdeleteSubBullet = false;

  openAddBulletModal(){
    this.isEditMode = false;
    this.bulletText = '';
    this.showBulletModal = true;
    this.isdeleteBullet = false;
  }

  openEditBulletModal() {
    if(this.selectedIndex !== null) {
      this.isEditMode = true;
      this.bulletText = this.bullets[this.selectedIndex];
      this.showBulletModal = true;
      this.isdeleteBullet = true;
    }
  }

  toggleBulletReorder(){
    this.isBulletReorder = !this.isBulletReorder;
  }

  toggleSubBulletReorder(){
    this.isSubBulletReorder = !this.isSubBulletReorder;
  }

  addBullet(){
    if(this.bulletText.trim()) {
      this.bullets.push(this.bulletText);
      this.subBulletsMap[this.bullets.length - 1] = [];
    }
    this.closeBulletModal();
  }

  editBullet() {
    if(this.selectedIndex !== null && this.bulletText.trim()){
      this.bullets[this.selectedIndex] = this.bulletText;
    }
    this.closeBulletModal();
  }

  deleteBullet() {
    if(this.selectedIndex !== null){
      this.bullets.splice(this.selectedIndex, 1);
      delete this.subBulletsMap[this.selectedIndex];
      this.selectedIndex = null;
    }
    this.closeBulletModal();
  }

  closeBulletModal() {
    this.showBulletModal = false;
    this.bulletText = '';
  }

  selectBullet(index: number, event: Event) {
    if(event.type === 'keyup' || event.type === 'click') {
      this.selectedIndex = index;
      this.selectedSubBulletIndex = null;
      this.subBulletText = '';
    }
  }

  openAddSubBulletModal(){
    if(this.selectedIndex !== null) {
      this.isEditSubBulletMode = false;
      this.subBulletText = '';
      this.showSubBulletModal = true;
      this.isdeleteSubBullet = false;
    }
  }

  openEditSubBulletModal(index: number | null) {
    if(this.selectedIndex !== null && index !== null && this.subBulletsMap[this.selectedIndex]) {
      this.isEditSubBulletMode = true;
      this.selectedSubBulletIndex = index;
      this.subBulletText = this.subBulletsMap[this.selectedIndex][index];
      this.showSubBulletModal = true;
      this.isdeleteSubBullet = true;
    }
  }

  addSubBullet(){
    if(this.selectedIndex !== null && this.subBulletText.trim()){
      if(!this.subBulletsMap[this.selectedIndex]) {
        this.subBulletsMap[this.selectedIndex] = [];
      }
      this.subBulletsMap[this.selectedIndex].push(this.subBulletText);
    }
    this.closeSubBulletModal();
  }

  editSubBullet() {
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.subBulletText.trim()){
      this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex] = this.subBulletText;
    }
    this.closeSubBulletModal();
  }

  deleteSubBullet() {
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null){
      this.subBulletsMap[this.selectedIndex].splice(this.selectedSubBulletIndex, 1);
      this.selectedSubBulletIndex = null;
    }
    this.closeSubBulletModal();
  }

  closeSubBulletModal() {
    this.showSubBulletModal = false;
    this.subBulletText = '';
    this.selectedSubBulletIndex = null;
  }

  selectSubBullet(index: number, event: Event) {
    if(event.type === 'keyup' || event.type === 'click') {
      this.selectedSubBulletIndex = index;
    }
  }

  moveUp(){
    if(this.selectedIndex !== null && this.selectedIndex > 0) {
      const currentBullet = this.bullets[this.selectedIndex];
      this.bullets[this.selectedIndex] = this.bullets[this.selectedIndex - 1];
      this.bullets[this.selectedIndex - 1] = currentBullet;

      const currentSubBullet = this.subBulletsMap[this.selectedIndex];
      this.subBulletsMap[this.selectedIndex] = this.subBulletsMap[this.selectedIndex - 1];
      this.subBulletsMap[this.selectedIndex - 1] = currentSubBullet;
      this.selectedIndex--;
    }
  }

  moveDown(){
    if(this.selectedIndex !== null && this.selectedIndex < this.bullets.length - 1) {
      const currentBullet = this.bullets[this.selectedIndex];
      this.bullets[this.selectedIndex] = this.bullets[this.selectedIndex + 1];
      this.bullets[this.selectedIndex + 1] = currentBullet;

      const currentSubBullet = this.subBulletsMap[this.selectedIndex];
      this.subBulletsMap[this.selectedIndex] = this.subBulletsMap[this.selectedIndex + 1];
      this.subBulletsMap[this.selectedIndex + 1] = currentSubBullet;
      this.selectedIndex++;
    }
  }

  moveSubBulletUp(){
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.selectedSubBulletIndex > 0){
      const subBullet = this.subBulletsMap[this.selectedIndex];
      const currentSubBullet = subBullet[this.selectedSubBulletIndex];
      subBullet[this.selectedSubBulletIndex] = subBullet[this.selectedSubBulletIndex - 1];
      subBullet[this.selectedSubBulletIndex - 1] = currentSubBullet;
      this.selectedSubBulletIndex--;
    }
  }

  moveSubBulletDown(){
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.selectedSubBulletIndex < (this.subBulletsMap[this.selectedIndex]?.length ?? 0) - 1){
      const subBullet = this.subBulletsMap[this.selectedIndex];
      const currentSubBullet = subBullet[this.selectedSubBulletIndex];
      subBullet[this.selectedSubBulletIndex] = subBullet[this.selectedSubBulletIndex + 1];
      subBullet[this.selectedSubBulletIndex + 1] = currentSubBullet;
      this.selectedSubBulletIndex++;
    }
  }

  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize() {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }

}
