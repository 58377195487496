

<div class="footerBox d-flex justify-content-between fixed-bottom bg-light" >
  <div class="d-flex copywriteText mt-2">
    <span><img [src]="myimage" alt="Toyota Logo"></span>
    <span>Copyrighted © {{currentYear}}</span>
  </div>
</div>



