import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { LookupService } from 'src/app/services/lookup.service';
import { formatDate } from '@angular/common';
import { checkMimeTypcv } from 'src/app/model/mimetype';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
interface FileData {
  name: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-emailer',
  templateUrl: './emailer.component.html',
  styleUrl: './emailer.component.css'
})


export class EmailerComponent implements OnInit{
  files: FileData[] = [];
  isAnyFilesChosen: boolean;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: GridApi;
  domLayout='autoHeight';
  fiscalid:any;
  fiscalyear:any;
  contentid:any;
  filter:any="approved";
  lastsend:any;
  lastsendtest:any;
  lastresend:any;
  lastresendtest:any;
  emailForm:FormGroup;
  emailbody:any;
  setfydisable:any;
  saveEmailerContentBody:any;
  sendBody:any;
  appParentId:any=[];
  config = {  headers: {  "Content-Type": "" } };
  progress = 0; 
  message = '';
  constructor(private readonly http: HttpClient,private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder
    , private readonly admin:AdminService,private readonly lookup:LookupService,
    ){}

  ngOnInit(): void {
    this.emailForm=this.formBuilder.group({
      fiscalYear:new FormControl(''),
      dueDate:new FormControl(''),
      filter:new FormControl(''),
      supplierFlag:new FormControl(''),
      emailBody:new FormControl(''),
      emailBodySurveyLink:new FormControl(''),
      excludeSurvey:new FormControl(''),
      test:new FormControl(''),
      emailRadio:new FormControl(''),
      recipients:new FormControl(''),
    })
    this.emailForm.controls['test'].setValue(true);
    this.showSupplierEmailerInfo();
  }

  saveEmail(){
    this.saveEmailerContentBody={
        "fiscalId": this.fiscalid,
        "fiscalYear": this.emailForm.controls['fiscalYear'].value,
        "saveContent": true,
        "filter": this.emailForm.controls['filter'].value,
        "supplierFlag": this.emailForm.controls['supplierFlag'].value,
        "reportPeriod": null,
        "emailBody": this.emailForm.controls['emailBody'].value,
        "hasSurveyLink": !this.emailForm.controls['excludeSurvey'].value,
        "surveyLink": this.emailForm.controls['emailBodySurveyLink'].value,
        "empId": sessionStorage.getItem("assumeEmployeeid")
    }
    this.admin.saveEmailerContent(this.saveEmailerContentBody).subscribe(_data=>{
    })
  }

  send(){ 
    this.saveEmailerContentBody={
      "fiscalId": this.fiscalid,
      "fiscalYear": this.emailForm.controls['fiscalYear'].value,
      "saveContent": false,
      "filter": this.emailForm.controls['filter'].value,
      "supplierFlag": this.emailForm.controls['supplierFlag'].value,
      "reportPeriod": null,
      "emailBody": this.emailForm.controls['emailBody'].value,
      "hasSurveyLink": !this.emailForm.controls['excludeSurvey'].value,
      "surveyLink": this.emailForm.controls['emailBodySurveyLink'].value,
      "empId": sessionStorage.getItem("assumeEmployeeid")
  }
  this.admin.saveEmailerContent(this.saveEmailerContentBody).subscribe(data=>{
    this.contentid=data.body.content[0].suppliermailcontentid;
    this.sendBody={
      "fiscalId": this.fiscalid,
      "fiscalYear": this.fiscalyear,
      "contentId": this.contentid,
      "filter": this.emailForm.controls['filter'].value,
      "recipients": this.emailForm.controls['recipients'].value,
      "sendType": this.emailForm.controls['emailRadio'].value,
      "isTest": this.emailForm.controls['test'].value,
      "hasBuyer": true,
      "attachments": this.isAnyFiles,
      "appParentId": this.appParentId
    }
    this.admin.sendEmail(this.sendBody).subscribe(event => {
      for (let i = 0; i < this.isAnyFiles.length; i++) {
        this.config.headers['Content-Type'] = this.isAnyFiles[i].mimeType;
        const upload = this.http.put(event.body.attachments[i], this.isAnyFiles[i].file, this.config).toPromise();
        upload.then(_data => {
        }).catch(_err => {
        })
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.message = event.body.message;
        }
      }
    })
  })
  }

  showSupplierEmails(filter){
    this.admin.getSupplierEmails(this.fiscalid,filter).subscribe(data=>{
      if(this.filter==="approved"){
      this.rowDataForEmailer=data.body.approved;}
      if(this.filter==="no_response"){
        this.rowDataForEmailer=data.body.no_response;}
      if(this.filter==="not_received"){
        this.rowDataForEmailer=data.body.not_received;}
    })
  }

  showSupplierFlags(){
    this.lookup.getSupplierFlags(this.fiscalid).subscribe(data=>{
      this.supplierFlagData=data.body.supplierFlagsActive;  
      this.emailForm.controls['supplierFlag'].setValue(this.supplierFlagData[0].title);
      this.emailForm.controls['filter'].setValue('approved');
    })
  }

  showSupplierEmailerInfo(){
    this.admin.getSupplierEmailerInfo().subscribe(data=>{
      this.emailForm.controls['fiscalYear'].setValue(data.body.fiscalYear[0].respperiod);
      if (data.body?.fiscalYear[0]?.duedate) {
        this.emailForm.controls.fiscalYear.disable();
        this.emailForm.controls.dueDate.disable();
        this.setfydisable=true;
        const dd = data.body?.fiscalYear[0]?.duedate;
        const utcDate = new Date(dd);
        const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
        this.emailForm.controls['dueDate'].setValue(formatDate(localDate, 'yyyy-MM-dd', 'en'));
        this.fiscalid=data.body?.fiscalYear[0]?.fiscalyearid;
        this.fiscalyear=data.body?.fiscalYear[0]?.respperiod;
        this.showSupplierEmails(this.filter);
        this.showSupplierFlags();
      }
      if (data.body?.content[0]?.body) {
        this.emailForm.controls['emailBody'].setValue(data.body?.content[0]?.body);
      }
      if (data.body.latestDate[0].latest_sent) {
        const latestSent = data.body.latestDate[0].latest_sent.replace(' ', 'T');
        const latestSentparsedDate = new Date(latestSent);
        const latestSentformattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(latestSentparsedDate);
        this.lastsend = latestSentformattedDate;
      }
      if (data.body.latestDate[0].latest_sent_test) {
        const latestSentTest = data.body.latestDate[0].latest_sent_test.replace(' ', 'T');
        const latestSentTestparsedDate = new Date(latestSentTest);
        const latestSentTestformattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(latestSentTestparsedDate);
        this.lastsendtest = latestSentTestformattedDate;
      }
      if (data.body.latestDate[0].latest_resent) {
        const latestResent = data.body.latestDate[0].latest_resent.replace(' ', 'T');
        const latestResentparsedDate = new Date(latestResent);
        const latestResentformattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(latestResentparsedDate);
        this.lastresend = latestResentformattedDate;
      }
      if (data.body.latestDate[0].latest_resent_test) {
        const latestResentTest = data.body.latestDate[0].latest_resent_test.replace(' ', 'T');
        const latestResentTestparsedDate = new Date(latestResentTest);
        const latestResentTestformattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(latestResentTestparsedDate);
        this.lastresendtest = latestResentTestformattedDate;
      }
    })
  }

  setfy(){
    this.emailbody={
      "fiscalYear": this.emailForm.controls['fiscalYear'].value,
      "dueDate": this.emailForm.controls['dueDate'].value,
      "empId": sessionStorage.getItem("assumeEmployeeid")
    }
    this.admin.updateEmailerFiscalYear(this.emailbody).subscribe(_data=>{
      this.showSupplierEmailerInfo();
    })
  }

  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    minWidth: 140,
    autoHeight: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true
   };

   icons = {
    menu: ' ',
    filter: ' '
   }

  supplierFilterData = [{"filterdata":'All Approved Suppliers',"value":"approved"}, {"filterdata":'Not Recieved',"value":"not_received"}, {"filterdata":'For No Sent Supplier Response',"value":"no_response"}];
  
  changeFilter(filter){
    this.filter=filter;
    if (filter === "approved") {
      this.showSupplierEmails("approved");
    }
    if (filter === "no_response" || filter === "not_received") {
      this.showSupplierEmails("");
    }
  }

  supplierFlagData = [];
  
  columnDefForEmailer = [
    {
      headerName: 'SUPPLIER',
      field: 'suppcode',
      flex: 1,
    }, {
      headerName: 'SUPPLIER NAME',
      field: 'supplier',
      flex: 1,
    }, {
      headerName: 'EMAIL (RECIPIENT)',
      field: 'recipientemailaddress',
      flex: 5,
    }
  ]
  rowDataForEmailer = []

  rowSelection = {
    mode: "multiRow",
  }

  onSelection() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.processSelectedData(selectedRows);
  }

  processSelectedData(_selectedRows: any) {
    this.appParentId=[];
    for(let i=0;i<_selectedRows.length;i++){
      this.appParentId[i]=_selectedRows[i].appparentid;
    }
  }
  isAnyFiles:any=[];
  fileType = 'application/pdf';
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if(input.files && input.files.length > 0) { 
    const fileType =  this.fileType;
    checkMimeTypcv(input.files, function (mimetype: any) { 
      if (mimetype === fileType) {
      const file = input.files[0];
      this.files.push({
        file:input.files[0],
        name: file.name,
        isSelected: false,
        fileName: file.name,
        mimeType: mimetype
      }); 
      input.value = '';
      } 
    }.bind(this));
    }
    this.onCheckboxSelected();
  }

  removeAttachment(index: number): void {
    this.files.splice(index, 1);
    this.onCheckboxSelected();
  }

  onCheckboxSelected(): void {
    this.isAnyFilesChosen = this.files.some((file) => this.isAnyFilesChosen = file.isSelected);

    if (this.isAnyFilesChosen) {
      this.files.forEach((file) => { 
        if (file.isSelected) {
          this.isAnyFiles.push(file)
        }
      });
    }
    this.isAnyFiles = this.isAnyFiles.filter((item, index) => {
      return this.isAnyFiles.indexOf(item) === index;
    });
    let isFiles=[];
    for(let i=0;i<this.isAnyFiles.length;i++){
      if(this.isAnyFiles[i].isSelected===true){
        isFiles.push(this.isAnyFiles[i]);
      }
    }
    this.isAnyFiles=isFiles;
  }

  onGridReady(params:any){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
}
